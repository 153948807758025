import styled from "styled-components";
import like from "../../../Assets/Images/like.svg";
import likehover from "../../../Assets/Images/likehover.svg";
import dislike from "../../../Assets/Images/dislike.svg";
import dislikehover from "../../../Assets/Images/dislikehover.svg";
import love from "../../../Assets/Images/love.svg";
import lovehover from "../../../Assets/Images/lovehover.svg";

export const StyledButtons = styled.button`
  border: 2px solid white;
  background: none;
  color: #9d9d9d;
  font-family: "Myriad Pro";
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  outline: none !important;
`;
export const WhatActivitiesStyles = styled.div`
  .container {
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    white-space: nowrap;
    position: relative;
    height: 400px;
  }

  .activity-header {
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    margin-top: 1rem;
  }

  .activity-text {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 400;
  }

  .carousel {
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .card {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 400px;
    height: 400px;
    transition: 500ms;
    border: none;
    outline: none;
  }

  .card-left {
    transform: translateX(-110%);
    transition: 500ms;
    opacity: 0.3;
  }

  .card-right {
    transform: translateX(110%);
    transition: 500ms;
    opacity: 0.3;
  }

  .card-inactive-left {
    transform: translateX(-400%);
    transition: 500ms;
    opacity: 0.3;
  }

  .card-inactive-right {
    transform: translateX(+400%);
    transition: 500ms;
    opacity: 0.3;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  .hidden{
    display: none;
    transition: none !important;
  }

  .ratings {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .like {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-image: url(${like});
    width: 6em;
    background-size: contain;
    background-repeat: no-repeat;
    height: 6em;
    cursor: pointer;
    &:hover {
      background-image: url(${likehover});
      background-size: contain;
      background-repeat: no-repeat;
      width: 6em;
      height: 6em;
    }
  }

  .dislike {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-image: url(${dislike});
    background-repeat: no-repeat;
    width: 6em;
    background-size: contain;
    height: 6em;
    cursor: pointer;
    &:hover {
      background-image: url(${dislikehover});
      background-size: contain;
      background-repeat: no-repeat;
      width: 6em;
      height: 6em;
    }
  }

  .love {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-image: url(${love});
    width: 6em;
    background-size: contain;
    background-repeat: no-repeat;
    height: 6em;
    cursor: pointer;
    &:hover {
      background-image: url(${lovehover});
      background-size: contain;
      background-repeat: no-repeat;
      width: 6em;
      height: 6em;
    }
  }
`;
