import {
  TripCharacteristicsStyles,
  StyledButtons,
} from "./trip-characteristics-styles";
import React from "react";

function TripCharacteristics(props) {
  function handleActive(characteristic) {
    // Unwrap object and change the value given the key
    props.setTripCharacteristics((tripCharacteristics) => ({
      ...tripCharacteristics,
      [characteristic]: !props.tripCharacteristics[characteristic],
    }));
  }

  return (
    <TripCharacteristicsStyles>
      <p className="header">What are you looking for in a trip?</p>
      <p className="subheader">
        You may click multiple choices. You can change this answer later on.
      </p>
      <div className="row pt-2">
        <div className="col">
          <StyledButtons
            onClick={() => handleActive("historical")}
            className={
              props.tripCharacteristics.historical
                ? "historicalactive"
                : "historical"
            }
          />
        </div>
        <div className="col">
          <StyledButtons
            onClick={() => handleActive("adventure")}
            className={
              props.tripCharacteristics.adventure
                ? "adventureactive"
                : "adventure"
            }
          />
        </div>
        <div className="col">
          <StyledButtons
            onClick={() => handleActive("nightlife")}
            className={props.tripCharacteristics.nightlife ? "nightlifeactive" : "nightlife"}
          />
        </div>

        <div className="col">
          <StyledButtons
            onClick={() => handleActive("beaches")}
            className={
              props.tripCharacteristics.beaches ? "beachesactive" : "beaches"
            }
          />
        </div>
        
        <div className="col">
          <StyledButtons
            onClick={() => handleActive("culture")}
            className={
              props.tripCharacteristics.culture ? "cultureactive" : "culture"
            }
          />
        </div>
      </div>
      {Object.values(props.tripCharacteristics).some(Boolean) === true && (
        <button className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
      {Object.values(props.tripCharacteristics).some(Boolean) !== true && (
        <button disabled className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
    </TripCharacteristicsStyles>
  );
}

export default TripCharacteristics;
