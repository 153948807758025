import { doc, collection, addDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const PostGameData = async (referrer, attributeTotals, selectionCounts, documentID, setDocumentID, resultsPosted) => {
    const date = new Date();
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    referrer = referrer ? referrer : "Other";
    const query = await doc(db, 'Game', `${referrer}`);
    const colRef = collection(query, year, month, day);

    console.log(referrer);
    
    try {
      console.log(attributeTotals);
      console.log(selectionCounts);

      if (!resultsPosted) {
        const docRef = await addDoc(colRef, {
            attributeTotals: attributeTotals,
            selectionCounts: selectionCounts,
            Time: new Date().toISOString(),
        })
        setDocumentID(docRef.id);
        console.log("Results posted successfully");
      } else {
        const docRef = doc(colRef, documentID);
        await setDoc(docRef, {
            attributeTotals,
            selectionCounts,
            Time: new Date().toISOString(),
        });
        console.log("Results Updated Successfully");
      }   
    } catch (error) {
        console.log("Error posting results");
        const errorDate = new Date();
        const errorString = errorDate.toString();
        await setDoc(doc(db, 'Game', 'Error'), {
            Error: error.toString(),
        });
    }
};

export default PostGameData;