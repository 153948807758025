import styled from "styled-components";
import experience from "../../../Assets/Images/experience.svg";
import relaxation from "../../../Assets/Images/relaxation.svg";
import celebration from "../../../Assets/Images/celebration.svg";
import experienceactivated from "../../../Assets/Images/experienceactivated.svg";
import relaxationactivated from "../../../Assets/Images/relaxationactivated.svg";
import celebrationactivated from "../../../Assets/Images/celebrationactivated.svg";
import celebrationhover from "../../../Assets/Images/celebrationhover.svg";
import relaxationhover from "../../../Assets/Images/relaxationhover.svg";
import experiencehover from "../../../Assets/Images/experiencehover.svg";
import familyhover from "../../../Assets/Images/familyhover.svg";
import family from "../../../Assets/Images/family.svg";
import familyactivated from "../../../Assets/Images/familyactivated.svg";
export const StyledButtons = styled.button`
  border: none;
  background: none;
  width: 200px;
  height: 200px;
`;

export const WhyTravelingStyles = styled.div`
  .box {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 40px 0;
    justify-content: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding-bottom: 5%;
  }

  .response {
    border: none;
    background: none;
    font-family: "Myriad Pro";
    font-weight: 400;
    font-style: normal;
    cursor: pointer;
    color: #005e84;
    padding-bottom: 1rem;
  }

  .why-header {
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    margin-top: 1rem;
  }

  
  .why-text {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 400;
  }

  .celebration-popup {
    text-align: center;
    display: flex;
    justify-content: center;
    border: none;
    background: none;
  }

  .celebration {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${celebration});
    background-size: contain;
    width: 200px;
    height: 200px;
    border: none;
    cursor: pointer;
    &:hover {
      background-image: url(${celebrationhover});
      background-size: contain;
      width: 200px;
      height: 200px;
    }
  }

  .celebrationactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${celebrationactivated});
    background-size: contain;
    width: 200px;
    height: 200px;
    cursor: pointer;
  }

  .family {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${family});
    background-size: contain;
    width: 200px;
    height: 200px;
    border: none;
    cursor: pointer;
    &:hover {
      background-image: url(${familyhover});
      background-size: contain;
      width: 200px;
      height: 200px;
    }
  }

  .familyactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${familyactivated});
    background-size: contain;
    width: 200px;
    height: 200px;
    cursor: pointer;
  }

  .col {
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .relaxation {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${relaxation});
    width: 200px;
    background-size: contain;
    height: 200px;
    cursor: pointer;
    &:hover {
      background-image: url(${relaxationhover});
      background-size: contain;
      width: 200px;
      height: 200px;
    }
    &:active {
      background-image: url(${relaxationactivated});
      background-size: contain;
      width: 200px;
      height: 200px;
    }
  }

  .relaxationactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${relaxationactivated});
    width: 200px;
    background-size: contain;
    height: 200px;
    cursor: pointer;
  }

  .experience {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${experience});
    background-size: contain;
    width: 200px;
    height: 200px;
    cursor: pointer;
    &:hover {
      background-image: url(${experiencehover});
      background-size: contain;
      width: 200px;
      height: 200px;
    }
    &:active {
      background-image: url(${experienceactivated});
      background-size: contain;
      width: 200px;
      height: 200px;
    }
  }

  .experienceactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${experienceactivated});
    width: 200px;
    background-size: contain;
    height: 200px;
    cursor: pointer;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    justify-content: space-evenly;
    color: #009999;
    font-family: "Myriad Pro";
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 25px;
  }
`;
