import styled from "styled-components";

export const SurveyStyles = styled.div`
  .survey-container {
    display: flex;
    flex-direction: column;
    margin: 2em;
  }

  .survey-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mui-back-icon {
    height: 0.85em;
    color: #009999;
  }

  .survey-number {
    color: #9d9d9d;
    font-family: "Myriad Pro";
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: gray;
    margin-right: 10px;
    cursor: pointer;
  }

  .arrow {
    border: solid #009999;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .circle-current {
    background-color: #009999;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .back-pages {
    color: #009999;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
  }

  .survey-blurb {
    color: #000000;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
  }

  .survey-instructions {
    color: #009999;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
  }

  .survey-question {
    
    border-style: solid;
    border-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

  .survey-pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;
  }

  .continue {
    padding-top: 0.125em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 0.125em;
    background-color: #009999;
    color: white;
    border-style: solid;
    border-radius: 0.5em;
    border-width: 0.125em;
    border-color: #009999;
    font-family: "Myriad Pro";
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
  }

  .continue:disabled {
    padding-top: 0.125em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 0.125em;
    background-color: gray;
    color: white;
    border-style: solid;
    border-radius: 0.5em;
    border-width: 0.125em;
    border-color: gray;
    font-family: "Myriad Pro";
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
  }
`;
