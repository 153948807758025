import styled from "styled-components";
import banner from "../../Assets/Images/home-banner.svg";
import mountain from "../../Assets/Images/mountain.svg";
import flight from "../../Assets/Images/flight-banner.svg";
export const HomeStyles = styled.div`

.home-banner {
  background-image: url(${banner});
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 800px;
  color: white;
}

.mountain-banner {
  background-image: url(${mountain});
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  color: white;
}

.package-location{
  font-family: "Myriad Pro";
  font-size: 14pt;
  font-weight: bold;
}

.package-description{
  font-family: "Myriad Pro";
  font-size: 28pt;
  color: #009999;
}

.package-rating{
  font-family: "Myriad Pro";
  font-size: 16pt;
  color: #009999;
  padding-left: 130px;
}

.d-flex{
  flex-direction: column;
}

.star-row{
  position: relative;
}

.stars{
  position: absolute;
  bottom:25%;
  left: -0.1%;
}

.flight-banner {
  background-image: url(${flight});
  height: 70vh;
  background-position: 60% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  justify-content: center;
  align-items: center;
  color: white;
  min-height: 400px;
  max-width: 100%;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: .5em;
}

.mountain-h1{
  font-size: 4vw;
  font-family: "Myriad Pro";
  color: white;
}

.flight-h1{
  font-size: 4vw;
  font-family: "Myriad Pro";
  color: white;
  text-align: center;
}

.home-h1{
  font-size: 40pt;
  font-family: "Myriad Pro";
  color: white;
  text-align: center;
}

.home-h2{
  font-size: 20pt;
  font-family: "Myriad Pro";
  color: white;
  text-align: center;
}

.mountain-p{
  font-size: 2vw;
  font-family: "Myriad Pro";
  color: white;

}

.banner-col{
  width: 100%;
  font-size: 4vw;
  font-family: "Myriad Pro";
}

.banner-row{
  width: 100%;
  align-items: flex-end;
 
}

.mountain-col{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}

.mountain-row{
  width: 100%;
  align-items: flex-end;
}

.banner-container{
  height: 100%;
  align-content: center;
  display: flex;
  text-align: right;
}

.package-header{
  font-family: "Myriad Pro"
  font-weight: bold;
  color: #005E84;
}

.button{
  border: none;
  border-radius: 5px;
  min-height: 60px;
  min-width: 160px;
  text-align: center;
  justify-content: center;
  font-size: 22pt;
  font-family "Myriad Pro"
  align-self: flex-end;
  background-color: #009999;
  color: white;
}`;
