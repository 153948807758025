import { StyledButtons, WhatActivitiesStyles } from "./what-activities-styles";
import { useState } from "react";
import backpacking from "../../../Assets/Images/backpacking.svg";
import photography from "../../../Assets/Images/photography.svg";
import food from "../../../Assets/Images/food.svg";
import wintersports from "../../../Assets/Images/winter-activities.svg";
import sightseeing from "../../../Assets/Images/sightseeing.svg";
function WhatActivities(props) {
  const [index, setIndex] = useState(0);

  const handlePrevious = () => {
    setIndex(index === 0 ? 0 : index - 1);
  };

  const Activities = [
    {
      id: "1",
      image: backpacking,
      activity: "nature_activities",
    },
    {
      id: "2",
      image: wintersports,
      activity: "winter_activities",
    },
    {
      id: "3",
      image: photography,
      activity: "gramworthy",
    },
    {
      id: "4",
      image: food,
      activity: "food_experience",
    },
    {
      id: "5",
      image: sightseeing,
      activity: "sightseeing",
    },
  ];

  function handleRating(value) {
    let currentActivity = Activities[index].activity;
    let currentSelections = props.activities;
    currentSelections[currentActivity] = value;
    props.setActivities(currentSelections);
    setIndex(
      index === Activities.length - 1 ? Activities.length - 1 : index + 1
    );
  }

  function handleCarousel() {
    return (
      <>
        {Activities.map((item, i) => {
          let className = "card";
          if (i === index) {
            className = "card";
          } else if (i === index - 1) {
            className = "card card-left";
          } else if (i === index + 1) {
            className = "card card-right";
          } else if (i === index + 2) {
            className = "card card-inactive-right";
          } else if (i === index - 2) {
            className = "card card-inactive-left";
          } else className = "hidden";

          return (
            <img
              key={item.id}
              className={className}
              src={item.id === index - 1 || index + 1 || index ? item.image : "" }
              alt={item.activity}
              style={{maxWidth: "100%"}}
            ></img>
          );
        })}
      </>
    );
  }

  return (
    <WhatActivitiesStyles>
      <p className="activity-header">
        Tell us what activities interest you!
      </p>
      <p className="activity-text">
        Swipe on as many as you’d like. You can always edit!
      </p>
      <div className="container">
        <div className="carousel">{handleCarousel()}</div>
      </div>
      <div className="button-container">
        <div className="d-flex">
          <StyledButtons
            className="dislike"
            onClick={() => handleRating("dislike")}
          ></StyledButtons>
          <StyledButtons
            className="like"
            onClick={() => handleRating("like")}
          ></StyledButtons>
          <StyledButtons
            className="love"
            onClick={() => handleRating("love")}
          ></StyledButtons>
        </div>
        <div className="ratings">
          <StyledButtons onClick={handlePrevious}>
            Previous Answer
          </StyledButtons>
        </div>
      </div>
      {Object.values(props.activities).some(Boolean) === true && (
        <button className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
      {Object.values(props.activities).some(Boolean) !== true && (
        <button disabled className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
    </WhatActivitiesStyles>
  );
}

export default WhatActivities;
