import styled from "styled-components";
import domestic from "../../../Assets/Images/domestic.svg";
import domesticactivated from "../../../Assets/Images/domesticactivated.svg";
import domestichover from "../../../Assets/Images/domestichover.svg";
import international from "../../../Assets/Images/international.svg";
import internationalactivated from "../../../Assets/Images/internationalactivated.svg";
import internationalhover from "../../../Assets/Images/internationalhover.svg";

export const StyledButtons = styled.button`
  border: none;
  background: none;
`;

export const TravelTypeStyles = styled.div`
  .type-header {
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    margin-top: 1rem;
  }

  .type-text {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 400;
  }

  .domestic {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${domestic});
    background-size: contain;
    width: 300px;
    height: 300px;
    border: none;
    cursor: pointer;
    &:hover {
      background-image: url(${domestichover});
      background-size: contain;
      width: 300px;
      height: 300px;
    }
  }

  .domesticactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${domesticactivated});

    background-size: contain;
    width: 300px;
    height: 300px;
    cursor: pointer;
  }

  .international {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${international});
    background-size: contain;
    width: 300px;
    height: 300px;
    border: none;
    cursor: pointer;
    &:hover {
      background-image: url(${internationalhover});
      background-size: contain;
      width: 300px;
      height: 300px;
    }
  }

  .internationalactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${internationalactivated});
    width: 300px;
    height: 300px;
    background-size: contain;
    cursor: pointer;
  }
`;
