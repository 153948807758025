import { NumPeopleStyles, StyledButtons } from "./num-people-styles";
import React, { useState } from "react";

function NumPeople(props) {
  const [PlusActive, setPlusActive] = useState("false");
  const [MinusActive, setMinusActive] = useState("false");

  const handleMinus = () => {
    switch (props.numTraveling) {
      case 1:
        return;
      case 2:
        props.setNumTraveling(props.numTraveling - 1);
        setMinusActive(!MinusActive);
        return;
      case 9:
        props.setNumTraveling(props.numTraveling - 1);
        setPlusActive(!PlusActive);
        break;
      default:
        props.setNumTraveling(props.numTraveling - 1);
        return;
    }
  };

  const handlePlus = () => {
    switch (props.numTraveling) {
      case 1:
        setMinusActive(!MinusActive);
        props.setNumTraveling(props.numTraveling + 1);
        return;
      case 8:
        props.setNumTraveling(props.numTraveling + 1);
        setPlusActive(!PlusActive);
        return;

      case 9:
        setMinusActive(!MinusActive);
        setPlusActive(!PlusActive);
        props.setNumTraveling(props.numTraveling - 8);
        return;

      default:
        props.setNumTraveling(props.numTraveling + 1);
        return;
    }
  };

  return (
    <NumPeopleStyles>
      <p className="many-header">How many people will be traveling?</p>
      <p className="many-text">
        The number you choose includes you. You can change this answer later on.
      </p>
      <div className="row">
        <div className="col text-center mb-4 d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex">
            <div className="padding d-none d-md-inline">
              <StyledButtons
                onClick={handleMinus}
                className={MinusActive ? "minusinactive" : "minusactive"}
              />
            </div>

            {PlusActive ? (
              <div className="NumBox">
                <div className="numText">{props.numTraveling}</div>
              </div>
            ) : (
              <div className="NumBox">
                <div className="numText">{props.numTraveling}+</div>
              </div>
            )}

            <div className="padding d-none d-md-inline">
              <StyledButtons
                onClick={handlePlus}
                className={PlusActive ? "plusactive" : "plusinactive"}
              />
            </div>
          </div>
          <div
            className="d-flex d-inline d-md-none"
            style={{
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <div className="padding">
              <StyledButtons
                onClick={handleMinus}
                className={MinusActive ? "minusinactive" : "minusactive"}
              />
            </div>

            <div className="padding">
              <StyledButtons
                onClick={handlePlus}
                className={PlusActive ? "plusactive" : "plusinactive"}
              />
            </div>
          </div>
        </div>
      </div>
      <button className="continue" onClick={props.nextQuestion}>
        Continue
      </button>
    </NumPeopleStyles>
  );
}

export default NumPeople;
