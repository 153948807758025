import React, { useEffect } from "react";
import HotelDatepicker from "hotel-datepicker";
import "hotel-datepicker/dist/css/hotel-datepicker.css";
import { useRef } from "react";
export function Calendar(props) {
  const textInput = useRef(null);
  useEffect(() => {
    var hotelDatePicker = new HotelDatepicker(textInput.current, {
      inline: true,
      hoveringTooltip: function (nights, startTime, hoverTime) {
        return nights + " Nights";
      },
      onSelectRange: function () {
        props.setWhenTraveling(hotelDatePicker.getValue());
        props.setNumNights(hotelDatePicker.getNights());
      },
      onDayClick: function () {
        props.setWhenTraveling();
      },
      i18n: {
        selected: "Your trip:",
        night: "Night",
        nights: "Nights",
        button: "Close",
        clearButton: "Clear",
        submitButton: "Submit",
        "checkin-disabled": "Check-in disabled",
        "checkout-disabled": "Check-out disabled",
        "day-names-short": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "day-names": [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        "month-names-short": [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        "month-names": [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        "error-more": "Date range should not be more than 1 night",
        "error-more-plural": "Date range should not be more than %d nights",
        "error-less": "Date range should not be less than 1 night",
        "error-less-plural": "Date range should not be less than %d nights",
        "info-more": "Please select a date range of at least 1 night",
        "info-more-plural": "Please select a date range of at least %d nights",
        "info-range": "Please select a date range between %d and %d nights",
        "info-range-equal": "Please select a date range of %d nights",
        "info-default": "Please select a date range",
        "aria-application": "Calendar",
        "aria-selected-checkin": "Selected as check-in date, %s",
        "aria-selected-checkout": "Selected as check-out date, %s",
        "aria-selected": "Selected, %s",
        "aria-disabled": "Not available, %s",
        "aria-choose-checkin": "Choose %s as your check-in date",
        "aria-choose-checkout": "Choose %s as your check-out date",
        "aria-prev-month": "Move backward to switch to the previous month",
        "aria-next-month": "Move forward to switch to the next month",
        "aria-close-button": "Close the datepicker",
        "aria-clear-button": "Clear the selected dates",
        "aria-submit-button": "Submit the form",
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <input
      id="input-id"
      type="text"
      ref={textInput}
      value={props.whenTraveling}
      style={{ visibility: "hidden" }}
    />
  );
}
