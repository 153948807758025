import React, { useState, useMemo, useEffect } from 'react';
import useCSVData from "../../Hooks/useCSVData.js";
import heart from "./images/heart.png";
import { GameStyles } from "./game-styles.js";
import { DestinationRankings } from "../../Helpers/survey-helpers/suggestion-algorithm/suggestion-data.js";
import { TripSuggestionStyles } from '../../Helpers/survey-helpers/trip-suggestion/trip-suggestion-styles.js';
import { useUserAuth } from '../../UserAuthContext.js';
import PostGameData from '../../Helpers/game-helpers/PostGameData.js';

const usedPairs = new Set();
const imageBank = Array.from({length: 32}, (_, i) => i);
let lastUsedPairs = [];

const excludedAttributes = new Set([
  'cost_flight', 'cost_hotel', 'cost_food', 'cost_activities', 'cost_week', 'image', 'travel_type'
]);

//Get 20 pairs without repeating
function generateStartingPairs() {
  //Control amount of image pairs to show
  var rounds = imageBank.length/2;

  var imageSet1 = [];
  var imageSet2 = [];
  const tempImageBank = [...imageBank];

  //Assign random images from bank to set1 and remove from bank as used
  while (imageSet1.length < rounds) {
    var rand = Math.floor(Math.random() * tempImageBank.length);
    imageSet1.push(tempImageBank[rand]);
    tempImageBank.splice(rand, 1);
  }

  //Same for set2
  while (imageSet2.length < rounds) {
    var rand = Math.floor(Math.random() * tempImageBank.length);
    imageSet2.push(tempImageBank[rand]);
    tempImageBank.splice(rand, 1);
  }

  return {imageSet1, imageSet2};
}


function getHighestValuedAttribute(imageAttributes) {
  let highestAttribute = "";
  let highestValue = -Infinity;

  Object.keys(imageAttributes).forEach(attribute => {
    if (attribute !== 'id') {
      const value = parseFloat(imageAttributes[attribute]);

      if (!isNaN(value) && value > highestValue) {
        highestValue = value;
        highestAttribute = attribute;
      }
    }
  });

  return highestAttribute;
}

//Get 2 unique indeces that have not been paired
function getNextPair(data) {
  let pair;
  let img1, img2;

  do {
    const index1 = Math.floor(Math.random() * imageBank.length);
    let index2;
  do {
    index2 = Math.floor(Math.random() * imageBank.length);
  } while (index1 === index2)
    img1 = imageBank[index1];
    img2 = imageBank[index2];
    pair = img1 < img2 ? `${img1}-${img2}` : `${img2}-${img1}`;

    const highestAttr1 = getHighestValuedAttribute(data[img1]);
    const highestAttr2 = getHighestValuedAttribute(data[img2]);
  } while (
    usedPairs.has(pair) || 
    lastUsedPairs.some(p => p.includes(img1.toString()) || 
    p.includes(img2.toString()))
  );

  usedPairs.add(pair);
  console.log(usedPairs);
  console.log(lastUsedPairs);

  lastUsedPairs.push(pair);
  if (lastUsedPairs.length > 5) {
    lastUsedPairs.shift();
  }

  return pair.split('-').map(Number);
}


export default function Home() {
  const { data, error } = useCSVData('/imageValues.csv');
  const [currentPair, setCurrentPair] = useState([0,0]);
  const [images, setImages] = useState([null, null]);
  const [imageSets, setImageSets] = useState(generateStartingPairs());
  const [showingUniquePairs, setShowingUniquePairs] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectionCounts, setSelectionCounts] = useState({});
  const [attributeTotals, setAttributeTotals] = useState({});
  
  const [showResults, setShowResults] = useState(false);
  const [resultsPosted, setResultsPosted] = useState(false);
  const [documentID, setDocumentID] = useState(null);
  const [showPopup, setShowPopup] = useState(true);

  const { referrer, location, userEmail, userName } = useUserAuth();

  
  React.useEffect(() => {
    if (data) {
      handleNextPair();
    }
  }, [data]);

  useEffect(() => {
    loadImages(currentPair);
  }, [currentPair]);


  // Function to dynamically import an image
  const loadImage = async (id) => {
    try {
      const image = await import(`./images/image${id}.jpg`);
      return image.default;
    } catch (error) {
      console.error(`Failed to load image with id ${id}`, error);
      return null;
    }
  };


  // Function to load images for the current pair
  const loadImages = async (pair) => {
    const [img1, img2] = await Promise.all([
      loadImage(pair[0]),
      loadImage(pair[1])
    ]);
    setImages([img1, img2]);
  };

  const handleNextPair = () => {
    if (showingUniquePairs) {
      if (currentIndex < imageSets.imageSet1.length) {
        const img1 = imageBank[imageSets.imageSet1[currentIndex]];
        const img2 = imageBank[imageSets.imageSet2[currentIndex]];
        const pair = img1 < img2 ? `${img1}-${img2}` : `${img2}-${img1}`;
        usedPairs.add(pair);
        setCurrentPair(pair.split('-').map(Number));
        setCurrentIndex(currentIndex + 1);
        console.log(usedPairs);
      }
      else {
        setShowingUniquePairs(false);
        const nextPair = getNextPair(data);
        setCurrentPair(nextPair);
      }
    }
    else {
      const nextPair = getNextPair(data);
      setCurrentPair(nextPair);
    }
  };

  const updateSelectionCounts = (selectedImageId, notSelectedImageId) => {
    setSelectionCounts(prevCounts => {
      const newCounts = { ...prevCounts };
  
      // Log selected image
      if (!newCounts[selectedImageId]) {
        newCounts[selectedImageId] = { selected: 0, notSelected: 0 };
      }
      newCounts[selectedImageId].selected += 1;
      console.log(`Image ${selectedImageId} has been selected ${newCounts[selectedImageId].selected} times.`);
  
      // Log not selected image
      if (!newCounts[notSelectedImageId]) {
        newCounts[notSelectedImageId] = { selected: 0, notSelected: 0 };
      }
      newCounts[notSelectedImageId].notSelected += 1;
      console.log(`Image ${notSelectedImageId} has been passed on ${newCounts[notSelectedImageId].notSelected} times.`);
  
      return newCounts;
    });
  };
  
  const updateAttributeTotals = (selectedImageId, notSelectedImageId) => {
    // Calculate attribute totals
    if (data) {
      const selectedImageAttributes = data[selectedImageId];
      const notSelectedImageAttributes = data[notSelectedImageId];
  
      setAttributeTotals(prevTotals => {
        const newTotals = { ...prevTotals };
        Object.keys(selectedImageAttributes).forEach(attribute => {
          if (attribute !== 'id') {
            if (!newTotals[attribute]) {
              newTotals[attribute] = 0;
            }
            newTotals[attribute] += parseFloat(selectedImageAttributes[attribute]);
          }
        });
  
        Object.keys(notSelectedImageAttributes).forEach(attribute => {
          if (attribute !== 'id') {
            if (!newTotals[attribute]) {
              newTotals[attribute] = 0;
            }
            newTotals[attribute] -= 0.5 * parseFloat(notSelectedImageAttributes[attribute]);
          }
        });
  
        console.log(newTotals);
        return newTotals;
      });
    }
  };
  
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  
    const selectedImageId = currentPair[index];
    const notSelectedImageId = currentPair[1 - index];
  
    // Update selection counts and attribute totals
    updateSelectionCounts(selectedImageId, notSelectedImageId);
    updateAttributeTotals(selectedImageId, notSelectedImageId);
  
    setTimeout(() => {
      handleNextPair();
      setSelectedImageIndex(null);
    }, 800);
  };


  const rankings = useMemo(() => {
    const destinationKeys = Object.keys(DestinationRankings);
    if (destinationKeys.length === 0) return [];
  
    const scores = destinationKeys.map(destinationKey => {
      const destination = DestinationRankings[destinationKey];
      let score = 0;
  
      // Calculate the score based on attributeTotals, excluding certain attributes
      Object.keys(attributeTotals).forEach(attribute => {
        if (!excludedAttributes.has(attribute) && destination[attribute]) {
          score += attributeTotals[attribute] * parseFloat(destination[attribute]);
        }
      });
  
      return {
        city: destinationKey,
        score: score,
        cost_activities: destination.cost_activities,
        cost_food: destination.cost_food,
        cost_hotel: destination.cost_hotel,
        cost_flight: destination.cost_flight,
        cost_week: destination.cost_week,
        image: destination.image,
        travel_type: destination.travel_type,
        celebration: destination.celebration,
        relaxation: destination.relaxation,
        experience: destination.experience,
        family: destination.family,
        beaches: destination.beaches,
        nightlife: destination.nightlife,
        adventure: destination.adventure,
        historical: destination.historical,
        culture: destination.culture,
        New_Zealand: destination.New_Zealand,
        Brazil: destination.Brazil,
        Italy: destination.Italy,
        Dominican_Republic: destination.Dominican_Republic,
        Spain: destination.Spain,
        Egypt: destination.Egypt,
        India: destination.India,
        Thailand: destination.Thailand,
        Japan: destination.Japan,
        nature_activities: destination.nature_activities,
        gramworthy: destination.gramworthy,
        winter_activities: destination.winter_activities,
        food_experience: destination.food_experience,
        sightseeing: destination.sightseeing
      };
    });
  
    scores.sort((a, b) => b.score - a.score);
    return scores;
  }, [attributeTotals]);

  const handleViewResults = async () => {
    if (showResults) {
      setShowResults(false);
    } else {
      setShowResults(true);
      await PostGameData(referrer, attributeTotals, selectionCounts, documentID, setDocumentID, resultsPosted);
      setResultsPosted(true);
    }
  }

  return (
    <div>

      {showResults ? (

        <TripSuggestionStyles>
        <div className="header">
          <div className="title-container">
            <h1 className="your-preferences">Your Suggestions:</h1>
          </div>
          <div className="description-container">
            <p className="description">
              All prices represent estimated cost per-person, per-day
            </p>
          </div>
        </div>
        <div>
        {rankings.slice(0, 10).map((ranking, index) => {
          const destination = DestinationRankings[ranking.city];
          if (!destination) return null; // Ensure destination exists
        
          return (
            <div key={index} className="row gy-3 box p-3 mb-5">
              <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                <span className="package-description text-center text-md-start">
                  {ranking.city}
                </span>
                
                <img className="img-fluid mt-2" src={destination.image} alt={ranking.city} />
              </div>
              <div className="col d-flex flex-column justify-content-center">
                <div className="row gy-3">
                  <div className="col-12">
                    <div className="cost d-flex flex-column text-center text-md-end">
                      Activities $
                      {destination.cost_activities * 1 * 1} {/* Adjust numNights and numTraveling as needed */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="cost d-flex flex-column text-center text-md-end">
                      Flight ${destination.cost_flight * 1} {/* Adjust numTraveling as needed */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="cost d-flex flex-column text-center text-md-end">
                      Food $
                      {destination.cost_food * 1 * 1} {/* Adjust numNights and numTraveling as needed */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="cost d-flex flex-column text-center text-md-end">
                      Hotels $
                      {destination.cost_hotel * 1 * Math.ceil(1 / 2)} {/* Adjust numNights and numTraveling as needed */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="button-container">
          <button onClick={() => handleViewResults()} className="continue-button">
            Continue Selecting
          </button>
        </div>
        </div>
        </TripSuggestionStyles>

      ) : (
    
        <GameStyles>
        
          {showPopup ? (
            <div className="popup-container">
              <div className="popup-text">
                <h1>Destination Game</h1>
                <p>
                  Choose which image is more appealing to you. It could be based on beauty, 
                  the activities depicted in the picture, or any other reason you prefer it!
                </p>
                <p>
                  Your results will be available after 10 selections, but you can always
                  continue selecting to improve their accuracy. 
                </p>
              </div>
              <button onClick={() => setShowPopup(false)} className="popup-button">Okay</button>
            </div>
          ) : (
            <div className="game-wrapper">
              <h1>Destination Game</h1>
              <h3>A "This or That" style game returning destination suggestions based on your preferences</h3>
              <div className="images-wrapper">
                <div onClick={() => handleImageClick(0)} className="image-container" id="left-image">
                  <img 
                    src={images[0]} 
                    alt={`Image ${currentPair[0]}`} 
                    className={`pair-image ${selectedImageIndex === 0 ? "selected" : selectedImageIndex === 1 ? "not-selected" : ""}`}
                    id="left-image"
                  />
                    {selectedImageIndex === 0 && <img src={heart} className="heart animate" alt="heart" />}
                </div>
                {usedPairs.size >= 11 && 
                  (<button
                    onClick={() => handleViewResults()} 
                    className="results-button">View results
                  </button>)
                }
                <div onClick={() => handleImageClick(1)} className="image-container" id="right-image">
                  <img src={images[1]}
                    alt={`Image ${currentPair[1]}`}
                    className={`pair-image ${selectedImageIndex === 1 ? "selected" : selectedImageIndex === 0 ? "not-selected" : ""}`}
                    id="right-image"
                  />
                    {selectedImageIndex === 1 && <img src={heart} className="heart animate" alt="heart" />}
                </div>
              </div>
            </div>
          )}
        </GameStyles>

      )}

    </div>
  );
}
