import { StartStyles } from "./start-styles";
import React from "react";
import { SurveyStyles } from "../../../Routes/survey/survey-styles";
import planet from "../../../Assets/Images/planet.png"
function Start(props) {
  return (
    <>
      <StartStyles>
        <div
          className="text-center text-md-start d-flex container-fluid pt-5 pb-5 align-items-center mobile-background"
          style={{
            backgroundSize: "100% 100%",
            flexGrow: 1,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row align-items-center gy-3">
              <div className="col-12 col-lg-5 col-md-6 d-flex flex-column ">
                <span className="dates-header">
                Discover your next adventure.
                </span>
                <span className="dates-subsubheader">
                  Try our Nina Discover quiz to find your dream destination in less than 5 minutes!
                </span>
                <SurveyStyles className="d-none d-md-inline">
                  <button
                    className="continue mt-3"
                    onClick={props.nextQuestion}
                  >
                    Get Started!
                  </button>
                </SurveyStyles>
              </div>
              <div className="col-12 col-md-6 col-lg-7 d-flex flex-column align-items-center">
                <img alt="" className="img-fluid" src={planet}></img>
                <SurveyStyles className="d-inline d-md-none">
                  <button
                    className="continue mt-3"
                    onClick={props.nextQuestion}
                  >
                    Get Started!
                  </button>
                </SurveyStyles>
              </div>
            </div>
          </div>
        </div>
      </StartStyles>
    </>
  );
}

export default Start;
