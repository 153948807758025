import { useState } from "react";
import { Form } from "react-bootstrap";
import { db } from "../../../firebase";
import { updateDoc, doc, setDoc } from "firebase/firestore";
import { useUserAuth } from "../../../UserAuthContext";
import { SurveyStars } from "./survey-stars";

export const SurveyRating = (props) => {
  const [rating, setRating] = useState(0);
  var { referrer } = useUserAuth();
  const [Feedback, setFeedback] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    referrer = referrer ? referrer : "Other";
    try {
      const docRef = doc(
        db,
        "Users",
        `${referrer}`,
        `${props.year}`, `${props.month}`, `${props.day}`,
        `${props.docID}`
      );
      await updateDoc(docRef, {
        Rating: rating,
        Feedback: Feedback,
      })
        .then((results) => {
          setFeedback("");
          setRating(0);
        })
        .then((results) => {
          alert("Thank you!");
        });
    } catch (error) {
      let date = new Date();
      let d = date.toString();
      await setDoc(doc(db, "Error", d), {
        Error: error.toString(),
      });
    }
  };

  return (
    <>
      <h1 className="package-header mt-3">How would you rate your results?</h1>
      <h2 className="package-header mb-5">Your feedback is appreciated.</h2>
      <div className="col-12 d-flex  text-start mb-5">
        <div className="d-flex column box p-3 text-center w-100">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <SurveyStars rating={rating} setRating={setRating} />
            </Form.Group>
            <Form.Group>
              <div className="text-start mt-5 mb-5">
                <div className="ps-3">
                  <label htmlFor="Feedback">Feedback</label>
                </div>
                <textarea
                  name="text"
                  rows="5"
                  className="p-3"
                  maxLength={"300"}
                  id="Feedback"
                  placeholder="Please provide your thoughts on the survey."
                  value={Feedback}
                  style={{ resize: "none", width: "100%" }}
                  wrap="soft"
                  onChange={(e) => setFeedback(e.target.value)}
                  required
                ></textarea>
              </div>
            </Form.Group>
            <button className="mb-4 btn btn-warning" type="Submit">
              Submit
            </button>
          </Form>
        </div>
      </div>
      <h1 className="package-header mt-3">Have another trip in mind?</h1>
      <button
        onClick={() => {
          window.location.reload();
        }}
        className="discovery-button mb-5"
      >
        Discover More Trips
      </button>
    </>
  );
};
