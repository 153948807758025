import styled from "styled-components";
import space from "../../../Assets/Images/space.png";
export const StartStyles = styled.div`
display: flex;
flex-grow: 1;
flex-direction: column;
    .dates-container{
        display: flex;
        flex-direction: column;
        align-items: center; 
        background-color: white;
        font-family: "Myriad Pro" !important;
        justify-content: center;
    }

    .mobile-background {
        height: 100%;
        display: flex;
        background-image:
    url(${space});
      }
      
     

    .dates-header{
        color: #fff;
        line-height: 1.2;
        font-family: "Myriad Pro";
        font-size: 36px;
        font-weight: 600;
        font-style: normal;
   
    }

    .dates-subheader{
        color: #fff;
        font-family: "Myriad Pro";
        font-size: 32px;
        font-weight: 400;
    }

    .hero-image {
        position: relative;
        background-image: url("path/to/hero-image.jpg");
        background-size: cover;
        background-position: center;
        height: 400px; /* Adjust the height as needed */
      }

    .dates-subsubheader{
        color: #fff;
        font-family: "Myriad Pro";
        font-size: 24px;
        font-weight: 400;
    }

    .header{
        color: #005e84;
        font-family: "Myriad Pro";
        font-size: 32px;
        font-weight: 600;
        font-style: normal;
        margin: 0;
        margin-top: 1rem;
      }
    
      .text {
        color: rgb(0, 94, 132);
        font-family: "Myriad Pro";
        font-size: 24px;
        font-weight: 400;
      }

    .depart-return{
        color: white;
    }

    .dates-boolean{
        display: flex;
        flex-direction: row;
        align-items: center; 
        justify-content: center;
        margin-top: 2em;
        margin-bottom: 1em;
    }

    .dates-known{
        padding-top: 1em;
        padding-left: 2.125em;
        padding-right: 2.125em;
        padding-bottom: 1em;
        margin-right: 1em;
        background-color: #009999;
        color: white;
        border-style: solid;
        border-radius: 1em;
        border-width: .125em;
        border-color: #009999;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }

    .dates-unknown{
        padding: 1em;
        background-color: white;
        color: #009999;
        border-style: solid;
        border-radius: 1em;
        border-width: .125em;
        border-color: #009999;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }

    .dates-calendar{
        display: flex;
        flex-direction: row;
        align-items: center; 
        justify-content: center;
        margin-bottom: 1em;
    }

    .calendar{
        display: inline-block;
        border-color: #009999;
        color: #009999;
        font-family: "Noto Sans"
        font-weight: bold;
    }

    .react-calendar__navigation__arrow react-calendar__navigation__prev2-button{
        display: none;
    }

    .react-calendar__month-view__days__day--weekend{
        color: #009999;
        font-family: "Noto Sans"
        font-weight: bold;
    }
  
    .react-calendar__month-view__days__day--neighboringMonth {
        color: #CFCFCF;
        font-family: "Noto Sans"
        font-weight: bold;
    }

    .react-calendar--doubleView {
        width: 700px;
    }

    .react-calendar--doubleView .react-calendar__viewContainer {
        display: flex;
        margin: -0.5em;
    }

    .react-calendar--doubleView .react-calendar__viewContainer > * {
        width: 50%;
        margin: 0.5em;
    }

    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    box-sizing: border-box;
    }

    .react-calendar button {
        margin: 0;
        border: 0;
        outline: none;
    }

    .react-calendar button:enabled:hover {
        cursor: pointer;
    } 

    .react-calendar__navigation {
        display: flex;
        height: 44px;
        margin-bottom: 1em;
    }

    .react-calendar__navigation button {
        min-width: 44px;
        background: none;
    }

    .react-calendar__navigation button:disabled {
        background-color: #f0f0f0;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
        background-color: #009999;
        color: white;
    }

    .react-calendar__month-view__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.75em;
    }

    .react-calendar__month-view__weekdays__weekday {
        padding: 0.5em;
    }

    .react-calendar__month-view__weekNumbers .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        font-weight: bold;
    }

    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
        padding: 2em 0.5em;
    }

    .react-calendar__tile {
        max-width: 100%;
        padding: 10px 6.6667px;
        background: none;
        text-align: center;
        line-height: 16px;
        color #009999;
    }

    .react-calendar__tile:disabled {
        background-color: #f0f0f0;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        background-color: #4ABFBF;
        color: white;
    }
    
    .react-calendar__tile--now {
        background: white;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: #009999;
        color: white;
    }

    .react-calendar__tile--hasActive {
        background: #009999;
        color: white;
    }

    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
        background: #009999;
        color: white;
    }

    .react-calendar__tile--active {
        background: #009999;
        color: white;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: #009999;
        color: white;
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
        background-color: #e6e6e6;
        color: white;
    }
`;
