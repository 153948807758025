import { StyledButtons, WhatCountriesStyles } from "./what-countries-styles";
import { useState } from "react";
import { Countries } from "./countries";
function WhatCountries(props) {
  const [index, setIndex] = useState(0);

  const handlePrevious = () => {
    setIndex(index === 0 ? 0 : index - 1);
  };

  function handleRating(value) {
    let currentCountry = Countries[index].country;
    let currentSelections = props.whatCountries;
    currentSelections[currentCountry] = value;
    props.setWhatCountries(currentSelections);
    setIndex(index === Countries.length - 1 ? Countries.length - 1 : index + 1);
  }

  function handleCarousel() {
    return (
      <>
        {Countries.map((item, i) => {
          let className = "card";
          if (i === index) {
            className = "card";
          } else if (i === index - 1) {
            className = "card card-left";
          } else if (i === index + 1) {
            className = "card card-right";
          } else if (i === index + 2) {
            className = "card card-inactive-right";
          } else if (i === index - 2) {
            className = "card card-inactive-left";
          } else className = "hidden";

          return (
            <img
              key={item.id}
              className={className}
              src={item.id === index - 1 || index + 1 || index ? item.image : "" }
              alt={item.country}
              style={{maxWidth: "100%"}}
            ></img>
          );
        })}
      </>
    );
  }

  return (
    <WhatCountriesStyles>
      <p className="country-header">
        Tell us what places interest you!
      </p>
      <p className="country-text">
        Swipe on as many as you’d like. You can always edit!
      </p>
      <div className="container">
        <div className="carousel">{handleCarousel()}</div>
      </div>
      <div className="button-container">
        <div className="d-flex">
          <StyledButtons
            className="dislike"
            onClick={() => handleRating("dislike")}
          ></StyledButtons>
          <StyledButtons
            className="like"
            onClick={() => handleRating("like")}
          ></StyledButtons>
          <StyledButtons
            className="love"
            onClick={() => handleRating("love")}
          ></StyledButtons>
        </div>
        <div className="ratings">
          <StyledButtons onClick={handlePrevious}>
            Previous Answer
          </StyledButtons>
        </div>
      </div>
      {Object.values(props.whatCountries).some(Boolean) === true && (
        <button className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
      {Object.values(props.whatCountries).some(Boolean) !== true && (
        <button disabled className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
    </WhatCountriesStyles>
  );
}

export default WhatCountries;
