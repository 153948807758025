import { Routes, Route, useNavigate } from "react-router-dom";
import { UserAuthContextProvider } from "./UserAuthContext";
import Home from "./Routes/home/home";
import Survey from "./Routes/survey/survey";
import Discover from "./Routes/discover/discover";
import Game from "./Routes/game/game";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./index.css";
import { useEffect } from "react";
import Layout from "./Helpers/layout";
import ReactGA from 'react-ga4';
import Privacy from "./Routes/privacy/privacy";
import { useLocation } from "react-router-dom";
ReactGA.initialize('G-Q57YBER85X');


// Send pageview with a custom path
function Redirect() {
  const navigate = useNavigate();
  // Add this code block to handle any unknown routes and redirect to the home page.
  useEffect(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  return <></>;
}

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "instant"});
  }, [pathname]);

  return (
    <UserAuthContextProvider>
      <Routes>
        {/* A common layout for all the pages */}
        <Route path="/" element={<Layout />}>
          <Route element={<Home />} />
          <Route index element={<Discover />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/game" element={<Game />} />
          <Route path="/privacy-policy" element={<Privacy />}/>
          <Route path="*" element={<Redirect />} />
        </Route>
      </Routes>
    </UserAuthContextProvider>
  );
}

export default App;
