import hiker from "../../Assets/Images/hiker-art.svg";
import process from "../../Assets/Images/discover-process.svg";
import clock from "../../Assets/Images/clock-art.svg";
import answers from "../../Assets/Images/change-answers.svg";
import itinerary from "../../Assets/Images/itinerary.svg";
import who from "../../Assets/Images/who-is-nina.svg";
import { useNavigate } from "react-router-dom";
import { DiscoverStyles } from "./discover-styles";
import DiscoverBanner from "../../Helpers/banners/discover-banner";

function Discover() {
  const navigate = useNavigate();

  return (
    <>
      <DiscoverBanner />
      <DiscoverStyles>
        <div className="container mt-5 text-center justify-content-center align-items-center">
          <div className="row">
            <div className="col text-center d-flex flex-column align-items-center justify-content-center">
              <h1>What is ‘Nina Discover’?</h1>
              <h2>
                Nina Discover is our state-of-the-art discovery tool! But what
                does it do?
              </h2>
              <p>
                Nina uses your likes, dislikes, and personal preferences to help
                build your dream trip. We then use our top-notch tech to help
                find and recommend multiple trip options!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col text-center d-flex align-items-center justify-content-center">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "500px",
                  maxWidth: "500px",
                }}
                alt=""
                src={hiker}
              ></img>
            </div>
          </div>
        </div>

        <div className="container mt-5 text-center justify-content-center align-items-center">
          <div className="row">
            <div className="col text-center d-flex flex-column align-items-center justify-content-center">
              <h1>How does Nina Discover work?</h1>
              <h2>
                It’s simple! Just answer a few questions to let us know what
                your looking for!
              </h2>
              <p>
                Afterward, we take your answers and supply you with multiple
                trips, packages, and deals that fit your exact needs!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col text-center d-flex flex-column align-items-center justify-content-center">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                alt=""
                src={process}
              ></img>
            </div>
          </div>
        </div>

        <div className="container mt-5 text-center justify-content-center align-items-center">
          <div className="row">
            <div className="col  text-center d-flex flex-column align-items-center justify-content-center">
              <h1>How long will it take?</h1>
              <h2>The entire process only takes about 4 minutes!</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "500px",
                  maxWidth: "500px",
                }}
                alt=""
                src={clock}
              ></img>
            </div>
          </div>
        </div>

        <div className="container mt-5 text-center justify-content-center align-items-center">
          <div className="row">
            <div className="col text-center d-flex flex-column  align-items-center justify-content-center">
              <h1>Can I change my answers or trip details?</h1>
              <h2>Yes!</h2>
              <p>
                One completed, you can always go back and discover something
                new! Or, if you like the suggested trips you can always
                customize it to be the experience you want!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "500px",
                  maxWidth: "500px",
                }}
                src={answers}
              ></img>
            </div>
          </div>
        </div>

        <div className="container mt-5 text-center justify-content-center align-items-center">
          <div className="row">
            <div className="col text-center d-flex discover align-items-center justify-content-center">
              <h1>Does Nina help with Itinerary?</h1>
              <h2>Yes! In fact, we can help you organize your trip!</h2>
              <p>
                Nina will not only help you organize your trip, but can house
                all of your information in one of our trip groups! From travel
                information to downloading tickets and keeping track of
                reservations, Nina is with you each step of the way!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "500px",
                  maxWidth: "500px",
                }}
                src={itinerary}
              ></img>
            </div>
          </div>
        </div>

        <div className="container mt-5 text-center justify-content-center align-items-center">
          <div className="row">
            <div className="col  text-center d-flex  align-items-center justify-content-center">
              <div className="text-box text-center">
                <h1>Let us help you pick your next dream trip!</h1>
                <button
                  onClick={() => {
                    navigate("/survey");
                  }}
                  className=" discover-button ps-5 pe-5"
                >
                  Nina Discover
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 ">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="mb-4 mt-4">
                <div className="row  mb-3">
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-4  text-center">
                    {
                      <img
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "500px",
                          maxWidth: "500px",
                        }}
                        src={who}
                      />
                    }
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xl-8">
                    <h2>
                      Nina Trvl is a group of experienced adventurers who want
                      to bring a more personalized, “you”-driven approach to the
                      travel industry.
                    </h2>
                    <p>
                      Traveling can open our minds to new possibilities and give
                      us new insights about the world we live in. It helps shape
                      us and makes the world a little smaller.
                    </p>
                    <p>
                      Here at Nina, we understand that your trip is a chapter in
                      your life’s story, and we want to help make it as
                      incredible as we can!
                    </p>
                    <p>
                      Whether you’re looking for an All-inclusive Island
                      get-away or pick your trip bit by bit, Nina is here to
                      support you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DiscoverStyles>
    </>
  );
}

export default Discover;
