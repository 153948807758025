import { NavbarBrand } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
function CustomNav() {
  return (
    <>
      <style>
        {`
          @media (max-width: 435px) {
            .navbar-brand svg {
              width: 100%;
              height: auto;
              min-width: 70px;
            }
          }
        `}
      </style>
      <Navbar
        collapseOnSelect
        className="pt-3 pb-3"
        style={{
          backgroundImage: "linear-gradient(to right, #005e84, #009999)",
        }}
        variant="dark"
      >
        <Container className="align-items-center">
          <NavbarBrand as={NavLink} to="/" style={{ color: "white" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="139.649"
              height="69.544"
              viewBox="0 0 139.649 69.544"
            >
              <g
                id="Group_2087"
                data-name="Group 2087"
                transform="translate(-0.017 -32.39)"
              >
                <path
                  id="Path_832"
                  data-name="Path 832"
                  d="M371.107,223.531a2.8,2.8,0,0,1-2.8-2.8V200.538a12.877,12.877,0,1,0-5.353,10.453,2.8,2.8,0,1,1,3.282,4.549,18.489,18.489,0,1,1,7.681-15v20.189A2.8,2.8,0,0,1,371.107,223.531Zm-40.648-2.638V200.7a18.488,18.488,0,1,0-36.976,0v20.188a2.8,2.8,0,1,0,5.609,0V200.7a12.879,12.879,0,1,1,25.758,0v20.188a2.8,2.8,0,1,0,5.609,0Zm-59.221,0V200.7a18.488,18.488,0,0,0-36.976,0v20.188a2.8,2.8,0,0,0,5.609,0V200.7a12.879,12.879,0,1,1,25.758,0v20.188a2.8,2.8,0,1,0,5.609,0Zm13.565-.211V185.021a2.8,2.8,0,0,0-5.609,0v35.661a2.8,2.8,0,0,0,5.609,0Z"
                  transform="translate(-234.245 -121.764)"
                  fill="currentColor"
                />
                <path
                  id="Path_833"
                  data-name="Path 833"
                  d="M420.655,87.712,411.1,80.763l6.138-5.589a.976.976,0,0,0-1.232-1.511L408.67,79l-7.335-5.334a.976.976,0,0,0-1.232,1.511l6.137,5.589-9.555,6.948a2.292,2.292,0,0,0,.526,3.992,2.283,2.283,0,0,0,2.365-.445l9.094-8.283,9.094,8.283a2.291,2.291,0,1,0,2.89-3.547Z"
                  transform="translate(-332.063 -29.99)"
                  fill="currentColor"
                  opacity="0"
                />
                <path
                  id="Path_834"
                  data-name="Path 834"
                  d="M313.472,131.809a9.3,9.3,0,0,0-9.3,9.3c0,4.035,5.736,10.337,8.2,12.858a1.539,1.539,0,0,0,2.206,0c2.464-2.521,8.2-8.823,8.2-12.858A9.3,9.3,0,0,0,313.472,131.809Zm0,13.606a4.534,4.534,0,1,1,4.535-4.534A4.535,4.535,0,0,1,313.472,145.415Z"
                  transform="translate(-265.336 -99.419)"
                  fill="currentColor"
                />
                <g
                  id="Group_2086"
                  data-name="Group 2086"
                  transform="translate(46.527 52.676)"
                  opacity="0"
                >
                  <path
                    id="Path_835"
                    data-name="Path 835"
                    d="M361.611,125.815a14.336,14.336,0,0,1-3.065-.329.719.719,0,0,1-.539-.892l.486-1.758a.71.71,0,0,1,.828-.509,11.248,11.248,0,0,0,2.911.22.71.71,0,0,1,.74.632l.2,1.812a.719.719,0,0,1-.671.8C362.206,125.806,361.908,125.815,361.611,125.815Zm6.514-2.327-.84-1.618a.71.71,0,0,1,.263-.938,10.856,10.856,0,0,0,2.28-1.8.709.709,0,0,1,.97-.045l1.39,1.18a.72.72,0,0,1,.053,1.044,14.088,14.088,0,0,1-3.112,2.465A.719.719,0,0,1,368.125,123.488Zm-15.791-1.176a14.579,14.579,0,0,1-2.55-3.017.72.72,0,0,1,.243-1.014l1.581-.907a.71.71,0,0,1,.95.226,11.331,11.331,0,0,0,1.885,2.238.71.71,0,0,1,.072.969l-1.14,1.423A.719.719,0,0,1,352.334,122.312Zm22.349-6.608-1.743-.536a.711.711,0,0,1-.484-.846,11.918,11.918,0,0,0,.324-2.8v-.18a.715.715,0,0,1,.715-.715h1.821a.715.715,0,0,1,.715.715v.18a15.15,15.15,0,0,1-.44,3.665A.719.719,0,0,1,374.683,115.7Zm-28.025-1.958-1.678-3.009a.715.715,0,0,1,.276-.973l1.59-.887a.715.715,0,0,1,.973.277l1.678,3.009a.716.716,0,0,1-.276.973l-1.59.886A.715.715,0,0,1,346.658,113.747Zm-26.092-3.15h-1.822a.72.72,0,0,1-.716-.758,14.658,14.658,0,0,1,.7-3.882.72.72,0,0,1,.944-.449l1.7.662a.71.71,0,0,1,.421.875,11.427,11.427,0,0,0-.517,2.882A.711.711,0,0,1,320.566,110.6Zm22.316-5.091a.715.715,0,0,1-.973-.277h0a11.88,11.88,0,0,0-1.756-2.4.71.71,0,0,1,0-.973l1.242-1.335a.718.718,0,0,1,1.043-.008,15.092,15.092,0,0,1,2.313,3.138h0a.715.715,0,0,1-.276.973Zm32.434.245H373.5a.715.715,0,0,1-.715-.715v-3.058a.715.715,0,0,1,.715-.715h1.821a.715.715,0,0,1,.715.715v3.058A.715.715,0,0,1,375.316,105.75ZM323.8,102.424l-1.3-1.279a.72.72,0,0,1,.024-1.045,14.241,14.241,0,0,1,3.272-2.234.719.719,0,0,1,.982.353l.728,1.671a.71.71,0,0,1-.327.918,11.033,11.033,0,0,0-2.409,1.642A.71.71,0,0,1,323.8,102.424Zm11.673-2.446a10.958,10.958,0,0,0-2.88-.423.71.71,0,0,1-.7-.682l-.078-1.821a.719.719,0,0,1,.728-.748,14.242,14.242,0,0,1,3.924.58.72.72,0,0,1,.477.931l-.614,1.717A.71.71,0,0,1,335.473,99.978Z"
                    transform="translate(-318.027 -96.304)"
                    fill="currentColor"
                  />
                </g>
              </g>
            </svg>
          </NavbarBrand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <NavLink className="nav-link" to="/survey">
                Nina Discover
              </NavLink>
              <NavLink className="nav-link" to="/game">
                Destination Game
              </NavLink>
              <a
                className="nav-link"
                href="https://calendly.com/dev-ninatrvl/experiment1et"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book Trips
              </a>
              <a
                className="nav-link"
                href="https://instagram.com/ninatrvl?igshid=OGIzYTJhMTRmYQ=="
                target="_blank"
                rel="noopener noreferrer"
              >
                Our Insta
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default CustomNav;
