import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCydmd7MAqEWEEpVcJSQXUfKv8hyAB42H4",
  authDomain: "nina-data-collection.firebaseapp.com",
  projectId: "nina-data-collection",
  storageBucket: "nina-data-collection.appspot.com",
  messagingSenderId: "1001673742095",
  appId: "1:1001673742095:web:90ad07f60da00a25d3507a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

export default app;
