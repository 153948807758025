import styled from "styled-components";
import banner from "../../Assets/Images/discover-banner.svg";
export const DiscoverStyles = styled.div`
  .home-banner {
    background-image: url(${banner});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: calc(0.375 * 100vw);
    min-height: 800px;
  }

  h1 {
    font-family: "Myriad Pro";
    color: #005e84;
    font-weight: bold;
    font-size: 48pt;
  }

  h2 {
    font-size: 32pt;
    font-family: "Myriad Pro";
    color: #005e84;
  }

  p {
    font-family: "Myriad Pro";
    font-size: 20pt;
  }

  .hacky-background {
    visibility: hidden;
  }

  .buttons {
    font-size: 0px;
    border: none;
    width: 180px;
  }

  .discover {
    flex-direction: column;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 1em; 
  }

  @media (max-width: 991px) {
    .button-container {
      flex-direction: column;
    }
  }

  .discover-button {
    background-color: #009999;
    color: white;
    font-family: "Myriad Pro";
    border: none;
    font-size: 25pt;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .who-img {
    display: flex;
    flex-direction: row;
  }

  .discover-process {
    max-width: 1382px;
  }

  .who-text {
    display: flex;
    flex-direction: column;
  }

  .button {
    border: none;
    background-color: white;
    padding-top: 10px;
    color: black;
    font-size: 12pt;
    font-family: "Myriad Pro";
    color: #005e84;

    height: 70px;
  }

  .button:focus {
    border: none;
    background-color: white;
    padding-top: 10px;
    color: black;
    font-size: 12pt;
    font-family: "Myriad Pro";
    color: #005e84;
    height: 70px;
    outline: none;
  }

  .checkbox-wrapper {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    flex-direction: row;
  }

  .box-container {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid whitesmoke;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .button-left {
    border-top-left-radius: 10px;
  }

  .button-right {
    border-top-right-radius: 10px;
  }

  .trip-select {
    border-radius: 10px;
    border: 1px solid #009999;
  }

  .active-background {
    background-image: linear-gradient(to right, #005e84, #009999);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .inactive-background {
    background-color: white;
  }

  label {
    font-family: "Myriad Pro";
    color: #009999;
    font-size: 20px;
  }

  .dropdown-text {
    font-family: "Myriad Pro";
    color: black;
    font-size: 18px;
  }

  .dropdown-flight {
    position: absolute;
    top: -30%;
  }
  .dropdown-flight-wrapper {
    position: relative;
    width: 45px;
  }

  .dropdown-traveler {
    position: absolute;
    top: -10%;
  }
  .dropdown-traveler-wrapper {
    position: relative;
    width: 30px;
  }

  input[type="checkbox"] {
    position: relative;
    visibility: hidden;
    cursor: pointer;
  }

  input[type="checkbox"]:after {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    visibility: visible;
    height: 14px;
    line-height: 0px;
    width: 14px;
    text-align: center;
    border: 1px solid green;
    background: white;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  input[type="checkbox"]:checked:after {
    background: #009999;
    content: "✓";
    color: white;
    height: 14px;
    line-height: 10px;
    width: 14px;
    text-align: center;
  }

  input[type="radio"] {
    position: relative;
    visibility: hidden;
    cursor: pointer;
  }

  input[type="radio"]:after {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    visibility: visible;
    height: 14px;
    line-height: 0px;
    width: 14px;
    text-align: center;
    border: 1px solid green;
    background: white;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  input[type="radio"]:checked:after {
    background: #009999;
    content: "✓";
    color: white;
    height: 14px;
    line-height: 10px;
    width: 14px;
    text-align: center;
  }

  .submit-btn {
    color: white;
    background-color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 20px;
    border-radius: 5px;
    border: none;
  }

  .learn-btn {
    width: 50%;
    color: #009999;
    font-family: "Myriad Pro";
    font-weight: bold;
    font-size: 32px;
    background-color: white;
    border-radius: 10px;
    border: none;
  }

  .thumb {
    width: "398px";
    height: "518px";
  }

  .btn-pressed {
    background-color: rgba(0, 0, 0, 0);
    border: none;

    padding-top: 10px;
    color: black;
    font-size: 12pt;
    font-family: "Myriad Pro";
    color: white;

    min-height: 70px;
    height: 100px;
    overflow-y: hidden;
  }

  .small-text {
    color: #009999;
    font-family: "Myriad Pro";
    font-size: 12px;
  }
`;
