import {
  WhatCelebratingStyles,
  StyledButtons,
} from "./what-celebrating-styles";
import { useState } from "react";
import popper from "../../../Assets/Images/popper.svg";

const WhatCelebrating = (props) => {
  const [BachelorActive, setBachelor] = useState("false");
  const handleBachelor = () => {
    setBachelor(false);
    setBachelorette(true);
    setBirthday(true);
    setHoneymoon(true);
    props.setCelebrationType("Bachelor Party");
  };
  const [BacheloretteActive, setBachelorette] = useState("false");
  const handleBachelorette = () => {
    setBachelor(true);
    setBachelorette(false);
    setBirthday(true);
    setHoneymoon(true);
    props.setCelebrationType("Bachelorette Party");
  };
  const [BirthdayActive, setBirthday] = useState("false");
  const handleBirthday = () => {
    setBachelor(true);
    setBachelorette(true);
    setBirthday(false);
    setHoneymoon(true);
    props.setCelebrationType("Birthday Party");
  };
  const [HoneymoonActive, setHoneymoon] = useState("false");
  const handleHoneymoon = () => {
    setBachelor(true);
    setBachelorette(true);
    setBirthday(true);
    setHoneymoon(false);
    props.setCelebrationType("Honeymoon");
  };

  return (
    <WhatCelebratingStyles>
      <p className="what-header">What are you celebrating? (optional)</p>
      <p className="what-text">Select a response.</p>

      <div className="row">
        <div className="col-xl-3 text-center text-xl-end">
          <div className="popper">
            <img src={popper} alt="popper"></img>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 text-center justify-content-center align-items-center mt-4 mb-4 d-flex">
          <div className="buttons">
            <StyledButtons
              onClick={handleBachelor}
              className={BachelorActive ? "bachelor" : "bacheloractive"}
            />
            <StyledButtons
              onClick={handleBachelorette}
              className={
                BacheloretteActive ? "bachelorette" : "bacheloretteactive"
              }
            />
          </div>
          <div className="buttons">
            <StyledButtons
              onClick={handleBirthday}
              className={BirthdayActive ? "birthday" : "birthdayactive"}
            />
            <StyledButtons
              onClick={handleHoneymoon}
              className={HoneymoonActive ? "honeymoon" : "honeymoonactive"}
            />
          </div>
        </div>
      </div>
      <button className="continue" onClick={props.nextQuestion}>
        Continue
      </button>
    </WhatCelebratingStyles>
  );
};

export default WhatCelebrating;
