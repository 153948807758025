import japan from "../../Assets/Images/japan-cities.svg";
import ireland from "../../Assets/Images/ireland-tour.svg";
import sanfran from "../../Assets/Images/san-fran-boat.svg";
import bahamas from "../../Assets/Images/bahama-cruise.svg";
import starFull from "../../Assets/Images/star-full.svg";
export const Packages = () => {
  return (
    <div className="container text-center">
      <h1 className="package-header pb-3">Recommended Vacation Packages</h1>
      <div className="row">
        <div className="col col-lg-6 d-flex col-xl-3  text-start mb-3">
          <img alt="cruise to the bahamas" src={bahamas}></img>
          <span className="package-location">Cruise to The Bahamas</span>
          <span className="package-description">6 Days</span>
          <div className="star-row pt-2">
            <span className="stars">
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
            </span>
            <span className="package-rating">5,123 Ratings</span>
          </div>
        </div>
        <div className="col col-lg-6 d-flex col-xl-3  text-start mb-3">
          <img alt="San Fran Boat Trip" src={sanfran}></img>
          <span className="package-location">
            Boat Trip Around San Francisco
          </span>
          <span className="package-description">3 Days</span>
          <div className="star-row pt-2">
            <span className="stars">
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
            </span>
            <span className="package-rating">5,123 Ratings</span>
          </div>
        </div>
        <div className="col col-lg-6 d-flex col-xl-3  text-start mb-3">
          <img alt="Tour of Ireland" src={ireland}></img>
          <span className="package-location">Ultimate Tour of Ireland</span>
          <span className="package-description">6 Days</span>
          <div className="star-row pt-2">
            <span className="stars">
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
            </span>
            <span className="package-rating">5,123 Ratings</span>
          </div>
        </div>
        <div className="col col-lg-6 d-flex col-xl-3  text-start mb-3">
          <img alt="Trip to Japan" src={japan}></img>
          <span className="package-location">Japan's Blossoming Cities</span>
          <span className="package-description">4 Days</span>
          <div className="star-row pt-2">
            <span className="stars">
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
              <img alt="ratings" width="15%" src={starFull}></img>
            </span>
            <span className="package-rating">5,123 Ratings</span>
          </div>
        </div>
        <div className="col-12 text-end">
          <span className="package-description">More Packages {">"}</span>
        </div>
      </div>
    </div>
  );
};
