import starFull from "../../../Assets/Images/star-full.svg";
import starEmpty from "../../../Assets/Images/star-empty.svg";

export const SurveyStars = (props) => {
  function handleRating(star) {
    switch (star) {
      case 1:
        if (props.rating >= 1) props.setRating(0);
        else props.setRating(1);
        return;
      case 2:
        if (props.rating >= 2) props.setRating(1);
        else props.setRating(2);
        return;
      case 3:
        if (props.rating >= 3) props.setRating(2);
        else props.setRating(3);
        return;
      case 4:
        if (props.rating >= 4) props.setRating(3);
        else props.setRating(4);
        return;
      case 5:
        if (props.rating >= 5) props.setRating(4);
        else props.setRating(5);
        return;
      default:
        return null;
    }
  }
  return (
    <>
      <div className="star-row pt-2">
        <span>
          <img
            alt="ratings"
            width={"100px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleRating(1);
            }}
            src={props.rating >= 1 ? starFull : starEmpty}
          ></img>
          <img
            alt="ratings"
            width={"100px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleRating(2);
            }}
            src={props.rating >= 2 ? starFull : starEmpty}
          ></img>
          <img
            alt="ratings"
            width={"100px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleRating(3);
            }}
            src={props.rating >= 3 ? starFull : starEmpty}
          ></img>
          <img
            alt="ratings"
            width={"100px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleRating(4);
            }}
            src={props.rating >= 4 ? starFull : starEmpty}
          ></img>
          <img
            alt="ratings"
            width={"100px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleRating(5);
            }}
            src={props.rating >= 5 ? starFull : starEmpty}
          ></img>
        </span>
      </div>
    </>
  );
};
