export const BudgetedResults = (props) => {
  const displayResults = () => {
    return (
      <>
        {props.suggestion.map((country, index) => {
          if (index < 5)
            return (
              <div key={index} className="row gy-3 box p-3 mb-5">
                <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                  <span className="package-description text-center text-md-start">
                    {country[0]}
                  </span>
                  <div className="text-center text-md-start">
                    <a
                      href="https://calendly.com/dev-ninatrvl/experiment1et"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="reserve-button">Reserve Now</button>
                    </a>
                  </div>
                  <img className="img-fluid mt-2" src={country[1].image} alt={country[0]} />
                </div>
                <div className="col d-flex flex-column justify-content-center">
                  <div className="row gy-3">
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Activities $
                        {country[1].cost_activities *
                          props.numNights *
                          props.numTraveling}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Flight ${country[1].cost_flight * props.numTraveling}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Food $
                        {country[1].cost_food *
                          props.numNights *
                          props.numTraveling}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Hotels $
                        {country[1].cost_hotel *
                          props.numNights *
                          Math.ceil(props.numTraveling / 2)}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Total ${country[1].cost}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          else return <></>;
        })}
      </>
    );
  };
  return (
    <>
      <h1 className="package-header mt-3 mb-5">
        These trips are perfect for your budget.
      </h1>

      {displayResults()}
    </>
  );
};
