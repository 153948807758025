import styled from "styled-components";

export const SurveyPopUpStyles = styled.div`
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .pop-up-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-radius: 1em;
    border-color: black;
    width: 100%;
    max-width: 480px;
    z-index: 3;
    background-color: #009999;
  }

  .animation-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100px;
  }

  .animation-container {
    width: 100%;
    height: 100%;
  }

  .animation-component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transition: 500ms;
  }

  .animation-component-island {
    transform: translateX(83%);
    transition: 500ms;
    top: 30%;
  }

  .animation-component-finish {
    transform: translateX(20%);
    transition: 800ms;
  }

  .animation-component-start {
    transform: translateX(-100%);
    transition: 500ms;
  }

  .pop-up-line {
    margin: 1em;
    border-style: solid;
    border-color: white;
    padding-right: 33%;
    padding-left: 33%;
    margin: 1em;
  }

  .pop-up-blurb-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pop-up-blurb {
    margin: 0.125em;
    color: white;
    font-size: 1.25em;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }

  .pop-up-continue {
    margin-top: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1.75em;
    padding-right: 1.75em;
    border-style: solid;
    border-radius: 0.5em;
    border-color: #009999;
    font-size: 1.75em;
    color: #009999;
    background-color: white;
    width: 250px;
    z-index: 3;
  }

  .pop-up-option {
    border-style: solid;
    border-radius: 0.5em;
    border-color: #009999;
    font-size: 1.75em;
    color: #009999;
    background-color: white;
    z-index: 3;
    width: 150px;
    margin-top: 1.5em;
  }

  .pop-up-finish {
    margin-top: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1.75em;
    padding-right: 1.75em;
    border-style: solid;
    border-radius: 0.5em;
    border-color: #009999;
    font-size: 1.75em;
    color: #005e84;
    background-color: #ffb703;
    width: 250px;
  }
`;
