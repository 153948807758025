import styled from "styled-components";
import plusactive from "../../../Assets/Images/plusactive.svg";
import restart from "../../../Assets/Images/restart.svg";
import minusactive from "../../../Assets/Images/minusactive.svg";
import minusinactive from "../../../Assets/Images/minusinactive.svg";

export const StyledButtons = styled.button`
  border: none;
  background: none;
`;

export const NumPeopleStyles = styled.div`
  .NumBox {
    width: 200px;
    height: 150px;
    border-radius: 17px;
    background-color: #00999940;
    display: inline-block;
    color: #000000;

    font-style: normal;
    justify-content: center;

    text-allign: center;
    position: relative;
  }

  .numText {
    position: absolute;
    font-size: 120pt;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Myriad Pro";

    font-weight: 700;
  }

  .padding {
    padding-top: 2rem;
  }

  .many-header {
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    margin-top: 1rem;
  }

  .many-text {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 400;
  }

  .minusactive {
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${minusactive});
    background-size: cover;
    width: 6em;
    height: 6em;
    border: none;

    margin-right: 1rem;
    cursor: pointer;
  }

  .minusinactive {
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${minusinactive});
    background-size: cover;
    width: 6em;
    height: 6em;

    margin-right: 1rem;
    cursor: pointer;
  }

  .plusactive {
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${plusactive});
    width: 6em;
    background-size: cover;
    height: 6em;
    cursor: pointer;

    margin-left: 1rem;
  }

  .plusinactive {
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${restart});
    width: 6em;
    background-size: cover;
    height: 6em;
    cursor: pointer;

    margin-left: 1rem;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    justify-content: center;
    color: #009999;
    font-family: "Myriad Pro";
    font-size: 3em;
    font-weight: 600;
    margin-bottom: 1em;
  }
`;
