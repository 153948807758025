import { TripSuggestionStyles } from "./trip-suggestion-styles";
import { BudgetedResults } from "./budgeted-results";
import { BudgetlessResults } from "./budgetless-results";
import { useEffect } from "react";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useUserAuth } from "../../../UserAuthContext";
import { useState } from "react";
import { SurveyRating } from "./survey-rating";
import axios from "axios";
const TripSuggestion = (props) => {
  var { referrer, location, userEmail, userName } = useUserAuth();
  const [docID, setDocID] = useState();
  const date = new Date();
  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
  const day = String(date.getDate()).padStart(2, '0');
  referrer = referrer ? referrer : "Other";

  useEffect(() => {
    async function PostSurveyResults() {
      try {
        const query = await doc(db, "Users", `${referrer}`);
        const colRef = collection(query, year, month, day);
        await addDoc(colRef, {
          SuggestionInterests: props.topThree
            .slice(0, 5)
            .map((country, index) => {
              if (index < 5) return country[0];
              else return null;
            }),
          SuggestionBudgeted: props.suggestion
            .slice(0, 5)
            .map((country, index) => {
              if (index < 5) return country[0];
              else return null;
            }),
          SelectedTravelType: props.travelType,
          SelectedTripBudget: props.tripBudget,
          SelectedNumberTraveling: props.numTraveling,
          SelectedWhenTraveling: props.whenTraveling,
          SelectedTripCharacteristics: props.tripCharacteristics,
          SelectedWhatCountries: props.whatCountries,
          SelectedActivities: props.activities,
          SelectedWhyTraveling: props.whyTraveling,
          SelectedNumberNights: props.numNights,
          Location: location,
          Email: userEmail,
          Name: userName,
          Rating: "",
          Feedback: "",
          Time: new Date().toISOString(),
          BudgetType: props.budgetType,
        }).then((docRef) => setDocID(docRef.id));
      } catch (error) {
        let date = new Date();
        let d = date.toString();
        await setDoc(doc(db, "Error", d), {
          Error: error.toString(),
        });
      }
    }
    async function SendSurveyResults() {
      const duplicatesToRemove = 5; // Specify the number of duplicates to remove
      let removedCount = 0;

      const filteredArray = props.topThree.filter((item) => {
        const countryName = item[0];
        const isDuplicate = props.suggestion.some(
          (otherItem) => otherItem[0] === countryName
        );

        if (isDuplicate && removedCount < duplicatesToRemove) {
          removedCount++;
          return false; // Exclude the duplicate from the filteredArray
        }

        return true; // Include non-duplicate items and remaining duplicates in the filteredArray
      });
      const body = {
        interests: filteredArray.slice(0, 5).map((country) => country[0]),
        budget: props.suggestion.slice(0, 5).map((country) => country[0]),
        recipient: userEmail,
      };

      if (userEmail !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
        axios.post(
          "https://qiltwme52npcaant43mwzkpzdi0kcnxh.lambda-url.us-east-1.on.aws/",
          body
        );
      }
    }
    PostSurveyResults();
    SendSurveyResults();
    // eslint-disable-next-line
  }, []);
  return (
    <TripSuggestionStyles>
      <div className="container text-center">
        <div className="row">
          <div className="col-12">
            <h1 className="package-header mt-5">Your Nina Discover Results!</h1>
            {props.suggestion.length > 0 && (
              <>
                <BudgetedResults
                  suggestion={props.suggestion}
                  topThree={props.topThree}
                  numTraveling={props.numTraveling}
                  numNights={props.numNights}
                />
                <BudgetlessResults
                  suggestion={props.suggestion}
                  numTraveling={props.numTraveling}
                  numNights={props.numNights}
                  topThree={props.topThree}
                />
              </>
            )}
            {props.suggestion.length === 0 && (
              <BudgetlessResults
                suggestion={props.suggestion}
                numTraveling={props.numTraveling}
                numNights={props.numNights}
                topThree={props.topThree}
              />
            )}
            <SurveyRating year={year} month={month} day={day} docID={docID} />
          </div>
          <h5 className="package-header mb-5">
            (Prices of trips are estimated and include the full package of
            flights, hotel, food, and activities for your selected trip length)
          </h5>
        </div>
      </div>
    </TripSuggestionStyles>
  );
};

export default TripSuggestion;
