import { DestinationRankings } from "./suggestion-data";
import { db } from "../../../firebase";
import { collection, getDocs, setDoc, doc } from "firebase/firestore";

function FilterByBudget(tripBudget, calculatedCost, budgetType) {
  var filteredByBudget = {};
  let value = tripBudget.overallBudget;
  const sum = Object.keys(tripBudget)
    .filter((key) => key !== "overallBudget")
    .reduce(
      (accumulator, currentValue) => accumulator + tripBudget[currentValue],
      0
    );
  if (budgetType === "Categorical") {
    value = sum;
  }
  if(isNaN(value))
  {
    value = 100
  }
  for (const destination in calculatedCost) {
    if (calculatedCost[destination].cost <= value) {
      // Add to new list
      filteredByBudget[destination] = calculatedCost[destination];
    }
  }
  return filteredByBudget;
}

function CalculateCost(destinationsData, numTraveling, numNights) {
  var calculatedCost = {};
  var cost;
  var destinations = {};
  var numHotelRooms = Math.ceil(numTraveling / 2);
  destinations = DestinationRankings;

  for (const destination in destinations) {
    cost =
      (destinations[destination].cost_flight +
        (destinations[destination].cost_food +
          destinations[destination].cost_activities) *
          numNights) *
        numTraveling +
      destinations[destination].cost_hotel * numHotelRooms * numNights;
    var costObj = { cost: cost };
    calculatedCost[destination] = destinations[destination];
    calculatedCost[destination] = {
      ...calculatedCost[destination],
      ...costObj,
    };
  }
  return calculatedCost;
}

function FilterByTravelType(travelType, filteredByBudget) {
  var potentialSuggestions = {};
  for (const destination in filteredByBudget) {
    if (
      filteredByBudget[destination].travel_type === "international" &&
      travelType.international
    ) {
      potentialSuggestions[destination] = filteredByBudget[destination];
    }

    if (
      filteredByBudget[destination].travel_type === "domestic" &&
      travelType.domestic
    ) {
      potentialSuggestions[destination] = filteredByBudget[destination];
    }
    if (!travelType.domestic && !travelType.international) {
      potentialSuggestions[destination] = filteredByBudget[destination];
    }
  }

  return potentialSuggestions;
}

function CalculateDestinations(
  potentialSuggestions,
  tripCharacteristics,
  whatCountries,
  activities,
  whyTraveling
) {
  var calculatedSuggestions = {};
  var sumObj = {};
  for (const destination in potentialSuggestions) {
    let sum = 0;
    Object.keys(tripCharacteristics).forEach((characteristic) => {
      if (tripCharacteristics[characteristic]) {
        sum += 100 * potentialSuggestions[destination][characteristic];
      }
    });
    Object.keys(whyTraveling).forEach((reason) => {
      if (whyTraveling[reason]) {
        sum += 150 * potentialSuggestions[destination][reason];
      }
    });
    Object.keys(whatCountries).forEach((country) => {
      if (whatCountries[country] === "love") {
        sum += 100 * potentialSuggestions[destination][country];
      } else if (whatCountries[country] === "like") {
        sum += 50 * potentialSuggestions[destination][country];
      } else if (whatCountries[country] === "dislike") {
        sum -= 50 * potentialSuggestions[destination][country];
      }
    });
    Object.keys(activities).forEach((activitiy) => {
      if (activities[activitiy] === "love") {
        sum += 100 * potentialSuggestions[destination][activitiy];
      } else if (activities[activitiy] === "like") {
        sum += 50 * potentialSuggestions[destination][activitiy];
      } else if (activities[activitiy] === "dislike") {
        sum -= 50 * potentialSuggestions[destination][activitiy];
      }
    });
    sumObj = { sum: sum };
    calculatedSuggestions[destination] = {
      ...potentialSuggestions[destination],
      ...sumObj,
    };
  }
  return calculatedSuggestions;
}

function Suggest(calculatedSuggestions) {
  var suggestion = [];
  for (var place in calculatedSuggestions) {
    suggestion.push([place, calculatedSuggestions[place]]);
  }

  suggestion.sort((a, b) => Number(b[1].sum) - Number(a[1].sum));

  // Returns top 3 sorted by value
  return suggestion;
}

function SuggestNoBudget(
  destinationsData,
  numTraveling,
  numNights,
  travelType,
  tripCharacteristics,
  whatCountries,
  activities,
  whyTraveling
) {
  var calculatedCost = {};
  var filteredByTravelType = {};
  var destinationsCalculated = {};
  var suggestions = [];
  calculatedCost = CalculateCost(destinationsData, numTraveling, numNights);
  filteredByTravelType = FilterByTravelType(travelType, calculatedCost);
  destinationsCalculated = CalculateDestinations(
    filteredByTravelType,
    tripCharacteristics,
    whatCountries,
    activities,
    whyTraveling
  );
  suggestions = Suggest(destinationsCalculated);
  return suggestions;
}

function SuggestBudgeted(
  destinationsData,
  numTraveling,
  numNights,
  travelType,
  tripCharacteristics,
  whatCountries,
  activities,
  tripBudget,
  whyTraveling,
  budgetType
) {
  var calculatedCost = {};
  var filteredByBudget = {};
  var filteredByTravelType = {};
  var destinationsCalculated = {};
  var suggestions = [];
  calculatedCost = CalculateCost(destinationsData, numTraveling, numNights);
  filteredByBudget = FilterByBudget(tripBudget, calculatedCost, budgetType);
  filteredByTravelType = FilterByTravelType(travelType, filteredByBudget);
  destinationsCalculated = CalculateDestinations(
    filteredByTravelType,
    tripCharacteristics,
    whatCountries,
    activities,
    whyTraveling
  );
  suggestions = Suggest(destinationsCalculated);
  return suggestions;
}

async function fetchData(setDestinationsData) {
  var Destinations = {};
  const querySnapshot = await getDocs(collection(db, "Destinations"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    Destinations[doc.id] = doc.data();
  });
  setDestinationsData(Destinations);
}

//THIS FUNCTION UPDATES THE FIRESTORE WITH THE CONTENTENTS OF THE DESTINATION RANKINGS JSON FILE DO NOT DELETE!!!
async function updateData(DestinationRankings) {
  Object.keys(DestinationRankings).map((destinations) => {
    const ref = doc(db, "Destinations", destinations);
    setDoc(ref, {
      travel_type: DestinationRankings[destinations].travel_type,
      cost_flight: DestinationRankings[destinations].cost_flight,
      cost_food: DestinationRankings[destinations].cost_food,
      cost_week: DestinationRankings[destinations].cost_week,
      beaches: DestinationRankings[destinations].beaches,
      nightlife: DestinationRankings[destinations].nightlife,
      adventure: DestinationRankings[destinations].adventure,
      historical: DestinationRankings[destinations].historical,
      culture: DestinationRankings[destinations].culture,
      New_Zealand: DestinationRankings[destinations].New_Zealand,
      Brazil: DestinationRankings[destinations].Brazil,
      Italy: DestinationRankings[destinations].Italy,
      Dominican_Republic: DestinationRankings[destinations].Dominican_Republic,
      Spain: DestinationRankings[destinations].Spain,
      Egypt: DestinationRankings[destinations].Egypt,
      India: DestinationRankings[destinations].India,
      Thailand: DestinationRankings[destinations].Thailand,
      Japan: DestinationRankings[destinations].Japan,
      nature_activities: DestinationRankings[destinations].nature_activities,
      gramworthy: DestinationRankings[destinations].gramworthy,
      winter_activities: DestinationRankings[destinations].winter_activities,
      food_experience: DestinationRankings[destinations].food_experience,
      sightseeing: DestinationRankings[destinations].sightseeing,
    });
    //shuts up demand for return statement
    return "shutup";
  });
}

export { SuggestBudgeted, SuggestNoBudget, fetchData, updateData };
