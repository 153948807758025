import { useEffect } from "react";
import { useUserAuth } from "../../UserAuthContext";

function Location() {
  const { setLocation } = useUserAuth();
  useEffect(() => {
    const updateLocation = () => {
      
        const apiUrl = `https://geolocation-db.com/json/`;
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            setLocation({
              state: data.state,
              country: data.country_name,
            });
      });
    };
   
      updateLocation();
    
    // eslint-disable-next-line
  }, []);
}

export default Location;
