import React from "react";
import ReactSlider from "react-slider";
import { TripBudgetStyles } from "./trip-budget-styles";
import styles from "./Budget.Modules.css";
import { Tabs, Tab } from "react-bootstrap";


function TripBudget(props) {
  function handleChange(e, budgetType) {
    const value = e.target.value;
    const parsedValue = parseInt(value);
    if (value === "") {
      props.setTripBudget((tripBudget) => ({
        ...tripBudget,
        [budgetType]: "",
      }));
    }
    if (!isNaN(parsedValue)) {
      if (budgetType === "overallBudget") {
        if (parsedValue > 30000) {
          handleValue(30000, budgetType);
        } else {
          handleValue(parsedValue, budgetType);
        }
      } else {
        if (parsedValue > 7000) {
          handleValue(7000, budgetType);
        } else {
          handleValue(parsedValue, budgetType);
        }
      }
    }
  }
  function handleValue(budgetValue, budgetType) {
    switch (budgetType) {
      case "overallBudget":
        props.setTripBudget((tripBudget) => ({
          ...tripBudget,
          [budgetType]: budgetValue,
        }));
        return;

      case "stayBudget":
        props.setTripBudget((tripBudget) => ({
          ...tripBudget,
          [budgetType]: budgetValue,
        }));
        return;

      case "activitiesBudget":
        props.setTripBudget((tripBudget) => ({
          ...tripBudget,
          [budgetType]: budgetValue,
        }));
        return;

      case "flightBudget":
        props.setTripBudget((tripBudget) => ({
          ...tripBudget,
          [budgetType]: budgetValue,
        }));
        return;

      case "transportationBudget":
        props.setTripBudget((tripBudget) => ({
          ...tripBudget,
          [budgetType]: budgetValue,
        }));
        return;
      case "diningBudget":
        props.setTripBudget((tripBudget) => ({
          ...tripBudget,
          [budgetType]: budgetValue,
        }));
        return;
      default:
        return;
    }
  }

  function displayBudget(budgetType) {
    switch (budgetType) {
      case "overallBudget":
        if (props.tripBudget.overallBudget !== 30000) {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.overallBudget}
              onChange={(e) => {
                handleChange(e, budgetType);
              }}
            />
          );
        } else {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.overallBudget}
              style={{ color: "#FFB703" }}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        }

      case "stayBudget":
        if (props.tripBudget.stayBudget !== 7000) {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.stayBudget}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        } else {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.stayBudget}
              style={{ color: "#FFB703" }}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        }

      case "activitiesBudget":
        if (props.tripBudget.activitiesBudget !== 7000) {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.activitiesBudget}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        } else {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.activitiesBudget}
              style={{ color: "#FFB703" }}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        }

      case "flightBudget":
        if (props.tripBudget.flightBudget !== 7000) {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.flightBudget}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        } else {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.flightBudget}
              style={{ color: "#FFB703" }}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        }

      case "transportationBudget":
        if (props.tripBudget.transportationBudget !== 7000) {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.transportationBudget}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        } else {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.transportationBudget}
              style={{ color: "#FFB703" }}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        }
      case "diningBudget":
        if (props.tripBudget.diningBudget !== 7000) {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.diningBudget}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        } else {
          return (
            <input
              className="custom-input"
              value={props.tripBudget.diningBudget}
              style={{ color: "#FFB703" }}
              onChange={(e) => handleChange(e, budgetType)}
            />
          );
        }
      default:
        return;
    }
  }

  return (
    <TripBudgetStyles>
      <p className="budget-top-header">What's this trip’s budget?</p>
      <p className="budget-top-text">
        Tell us a general amount, or break it up by category!
      </p>
      <p className="budget-top-text">
        We use your location to provide personalized flight options and deals
        that are specific to your area.
      </p>

      <Tabs
        fill
        id="controlled-tab-example"
        activeKey={props.budgetType}
        style={{ justifyContent: "center" }}
        onSelect={(k) => props.setBudgetType(k)}
        className={`${styles.tabs} mb-3`}
      >
        <Tab eventKey="Overall" title="Overall">
          <h1 className="budget-middle-header">Overall Budget</h1>
          <h2 className="budget-middle-text">
            Tell us what you generally want to spend.
          </h2>

          <div className="row justify-content-center">
            <div className="col-xl-12 pt-3 mb-3 text-center align-items-center justify-content-center d-flex flex-column">
              {displayBudget("overallBudget")}
              <ReactSlider
                key="1"
                ariaLabelledby="slider-label"
                className="mt-3 horizontal-slider"
                min={0}
                value={props.tripBudget.overallBudget}
                max={30000}
                marks={[4000, 8000, 12000, 16000, 20000, 24000]}
                markClassName="slider-mark"
                trackClassName="slider-track"
                onChange={(value, index) => handleValue(value, "overallBudget")}
                thumbClassName="slider-thumb"
                step={10}
              />
            </div>
          </div>
        </Tab>
        <Tab eventKey="Categorical" title="Categorical">
          <h1 className="budget-middle-header">Categorical Budget</h1>
          <h2 className="budget-middle-text">
            Break your budget up by category.
          </h2>
          <div className="row justify-content-center">
            <div className="col-xl-12 pt-3 mb-3 text-center align-items-center justify-content-center d-flex flex-column">
              <p className="small-slider-header">Stay Budget</p>
              {displayBudget("stayBudget")}
              <ReactSlider
                key="1"
                ariaLabelledby="slider-label"
                className="horizontal-slider mt-3"
                min={0}
                max={7000}
                marks={[1000, 2500, 4000, 5500]}
                value={props.tripBudget.stayBudget}
                markClassName="slider-mark"
                trackClassName="slider-track"
                onChange={(value, index) => handleValue(value, "stayBudget")}
                thumbClassName="slider-thumb"
                step={10}
              />
            </div>
            <div className="col-xl-12 pt-3 mb-3 text-center align-items-center justify-content-center d-flex flex-column">
              <p className="small-slider-header">Activities Budget</p>

              {displayBudget("activitiesBudget")}
              <ReactSlider
                key="1"
                ariaLabelledby="slider-label"
                className="horizontal-slider mt-3"
                min={0}
                value={props.tripBudget.activitiesBudget}
                max={7000}
                marks={[1000, 2500, 4000, 5500]}
                markClassName="slider-mark"
                trackClassName="slider-track"
                onChange={(value, index) =>
                  handleValue(value, "activitiesBudget")
                }
                thumbClassName="slider-thumb"
                step={10}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-12 pt-3 mb-3 text-center align-items-center justify-content-center d-flex flex-column">
              <p className="small-slider-header">Flight Budget</p>
              {displayBudget("flightBudget")}
              <ReactSlider
                key="1"
                ariaLabelledby="slider-label"
                className="horizontal-slider mt-3"
                min={0}
                max={7000}
                value={props.tripBudget.flightBudget}
                marks={[1000, 2500, 4000, 5500]}
                markClassName="slider-mark"
                trackClassName="slider-track"
                onChange={(value, index) => handleValue(value, "flightBudget")}
                thumbClassName="slider-thumb"
                step={10}
              />
            </div>
            <div className="col-xl-12 pt-3 mb-3 text-center align-items-center justify-content-center d-flex flex-column">
              <p className="small-slider-header">Transportation Budget</p>
              {displayBudget("transportationBudget")}
              <ReactSlider
                key="1"
                ariaLabelledby="slider-label"
                className="horizontal-slider mt-3 mb-2"
                min={0}
                max={7000}
                marks={[1000, 2500, 4000, 5500]}
                markClassName="slider-mark"
                trackClassName="slider-track"
                value={props.tripBudget.transportationBudget}
                onChange={(value, index) =>
                  handleValue(value, "transportationBudget")
                }
                thumbClassName="slider-thumb"
                step={10}
              />
            </div>
            <div className="col-xl-12 pt-3 mb-3 text-center align-items-center justify-content-center d-flex flex-column">
              <p className="small-slider-header">Dining Budget</p>
              {displayBudget("diningBudget")}
              <ReactSlider
                key="1"
                ariaLabelledby="slider-label"
                className="horizontal-slider mt-3 mb-2"
                min={0}
                max={7000}
                marks={[1000, 2500, 4000, 5500]}
                markClassName="slider-mark"
                trackClassName="slider-track"
                value={props.tripBudget.diningBudget}
                onChange={(value, index) => handleValue(value, "diningBudget")}
                thumbClassName="slider-thumb"
                step={10}
              />
            </div>
          </div>
        </Tab>
      </Tabs>

      <button className="continue" onClick={props.nextQuestion}>
        Continue
      </button>
    </TripBudgetStyles>
  );
}

export default TripBudget;
