import styled from "styled-components";
import historicalSignificance from "../../../Assets/Images/historicalSignificance.svg";
import historicalSignificanceHover from "../../../Assets/Images/historicalSignificanceHover.svg";
import historicalSignificanceActivated from "../../../Assets/Images/historicalSignificanceActivated.svg";
import adventure from "../../../Assets/Images/adventure.svg";
import adventureHover from "../../../Assets/Images/adventurehover.svg";
import adventureActivated from "../../../Assets/Images/adventureactive.svg";
import nightlife from "../../../Assets/Images/nightlife.svg";
import nightlifeHover from "../../../Assets/Images/nightlifehover.svg";
import nightlifeActivated from "../../../Assets/Images/nightlifeactive.svg";
import culture from "../../../Assets/Images/culture.svg";
import cultureHover from "../../../Assets/Images/culturehover.svg";
import cultureActivated from "../../../Assets/Images/cultureactive.svg";
import beaches from "../../../Assets/Images/beaches.svg";
import beachesHover from "../../../Assets/Images/beacheshover.svg";
import beachesActivated from "../../../Assets/Images/beachesactive.svg";

export const StyledButtons = styled.button`
  border: none;
  background: none;
`;

export const TripCharacteristicsStyles = styled.div`
  .header {
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    margin-top: 1rem;
  }

  .subheader {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 400;
  }

  .trip-characteristics-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .trip-characteristics-left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .trip-characteristics-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .historical {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${historicalSignificance});
    background-size: contain;
    width: 22em;
    height: 16em;
    cursor: pointer;
    &:hover {
      background-repeat: no-repeat;
      background-image: url(${historicalSignificanceHover});
      background-size: contain;
      width: 22em;
      height: 16em;
    }
  }

  .historicalactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${historicalSignificanceActivated});
    width: 22em;
    background-size: contain;
    height: 16em;
    cursor: pointer;
  }

  .adventure {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${adventure});
    background-size: contain;
    width: 22em;
    height: 16em;
    cursor: pointer;
    &:hover {
      background-repeat: no-repeat;
      background-image: url(${adventureHover});
      background-size: contain;
      width: 22em;
      height: 16em;
    }
  }

  .adventureactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${adventureActivated});
    width: 22em;
    background-size: contain;
    height: 16em;
    cursor: pointer;
  }

  .nightlife {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${nightlife});
    background-size: contain;
    width: 22em;
    height: 16em;
    cursor: pointer;
    &:hover {
      background-repeat: no-repeat;
      background-image: url(${nightlifeHover});
      background-size: contain;
      width: 22em;
      height: 16em;
    }
  }

  .nightlifeactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${nightlifeActivated});
    width: 22em;
    background-size: contain;
    height: 16em;
    cursor: pointer;
  }

  .culture {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${culture});
    background-size: contain;
    width: 22em;
    height: 16em;
    cursor: pointer;
    &:hover {
      background-repeat: no-repeat;
      background-image: url(${cultureHover});
      background-size: contain;
      width: 22em;
      height: 16em;
    }
  }

  .cultureactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${cultureActivated});
    width: 22em;
    background-size: contain;
    height: 16em;
    cursor: pointer;
  }

  .beaches {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${beaches});
    background-size: contain;
    width: 22em;
    height: 16em;
    cursor: pointer;
    &:hover {
      background-repeat: no-repeat;
      background-image: url(${beachesHover});
      background-size: contain;
      width: 22em;
      height: 16em;
    }
  }

  .beachesactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${beachesActivated});
    width: 22em;
    background-size: contain;
    height: 16em;
    cursor: pointer;
  }

  

  .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    justify-content: space-evenly;
    color: #009999;
    font-culture: "Myriad Pro";
    font-size: 3em;
    font-weight: 600;
  }

  @media only screen and (max-width: 767px) {
    .logo {
      display: none;
    }
  }
`;
