import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import nina from "../../Assets/Images/nina-footer-logo.svg";
function Footer() {
  return (
    <Navbar
      style={{ backgroundImage: "linear-gradient(to right, #005e84, #009999)" }}
      variant="dark"
      expand="md"
    >
      <Container>
        <div className="row">
          <div className="col">
            <Nav>
              <NavLink to="/" className="nav-link">
                <img src={nina} height={"100px"} alt="Nina"></img>
              </NavLink>
            </Nav>
            <Nav className="ms-auto d-flex flex-column">
              <div className="d-flex flex-column">
                <a
                  href="https://calendly.com/dev-ninatrvl/experiment1et"
                  target="_blank"
                  className="nav-link mt-2"
                  rel="noopener noreferrer"
                >
                  Book Trips
                </a>
              </div>
              <NavLink className={"nav-link"} to={"/privacy-policy"}>Privacy Policy</NavLink>
            </Nav>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default Footer;
