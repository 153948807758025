import { StyledButtons, WhyTravelingStyles } from "./why-traveling-styles";
import React from "react";

function WhyTraveling(props) {
  function handleActive(type) {
    /*if (type === "celebration") {
      props.setWhyTraveling((whyTraveling) => ({
        ...whyTraveling,
        [type]: !props.whyTraveling[type],
      }));
    } else {*/
      // Unwrap object and change the value given the key
      props.setWhyTraveling((whyTraveling) => ({
        ...whyTraveling,
        [type]: !props.whyTraveling[type],
      }));
    
  }

  return (
    <WhyTravelingStyles>
      <div className="row mb-4">
        <p className="why-header">Why are you traveling?</p>
        <p className="why-text">
          You may click multiple choices. You can change this answer later on.
        </p>
        <div className="col-12 col-md-6 col-lg-3 text-center">
          <StyledButtons
            onClick={() => handleActive("family")}
            className={
              props.whyTraveling.family ? "familyactive" : "family"
            }
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3 text-center">
          <StyledButtons
            onClick={() => handleActive("celebration")}
            className={
              props.whyTraveling.celebration ? "celebrationactive" : "celebration"
            }
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3 text-center">
          <StyledButtons
            onClick={() => handleActive("relaxation")}
            className={
              props.whyTraveling.relaxation ? "relaxationactive" : "relaxation"
            }
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3 justify-content-center align-items-center">
          <StyledButtons
            onClick={() => handleActive("experience")}
            className={
              props.whyTraveling.experience ? "experienceactive" : "experience"
            }
          />
        </div>
      </div>
      {Object.values(props.whyTraveling).some(Boolean) === true && (
        <button className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
      {Object.values(props.whyTraveling).some(Boolean) !== true && (
        <button disabled className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
    </WhyTravelingStyles>
  );
}

export default WhyTraveling;
