import { Outlet } from "react-router-dom";
import Footer from "./footer/footer";
import CustomNav from "./navigation/navigation";
// render navbar body and footer
const Layout = () => {
  return (
    <div className="wrapper">
     <CustomNav/>
      <div className="content">
      <Outlet /> 
      </div>
      <Footer />
    </div>
  );
};

export default Layout;