import { DatesStyles } from "./traveling-dates-styles";
import React from "react";
import { Calendar } from "./calendar";
function Dates(props) {
  return (
    <DatesStyles>
      <div className="dates-container">
        <span className="dates-header">Do you know when you're traveling?</span>
        <span className="dates-subheader">
          Please select the length of your trip.
        </span>

        <div className="row">
          <div className="col text-center mb-4">
            <Calendar
              whenTraveling={props.whenTraveling}
              setWhenTraveling={props.setWhenTraveling}
              setNumNights={props.setNumNights}
              numNights={props.numNights}
            />
          </div>
        </div>
      </div>
      {props.whenTraveling && (
        <button className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
      {!props.whenTraveling && (
        <button disabled className="continue">
          Continue
        </button>
      )}
    </DatesStyles>
  );
}

export default Dates;
