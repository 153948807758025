import { TravelTypeStyles, StyledButtons } from "./travel-type-styles";
import React from "react";

function TravelType(props) {

  function handleActive(type) {
    // Unwrap object and change the value given the key
    props.setTravelType((travelType) => ({
      ...travelType,
      [type]: !props.travelType[type],
    }));
  }

  return (
    <TravelTypeStyles>
      <p className="type-header">What type of traveling will you be doing?</p>
      <p className="type-text">
        You may click multiple choices. You can change this answer later on.
      </p>
      <div className="text-center mb-4">
        <StyledButtons
          onClick={() => handleActive("international")}
          className={
            props.travelType.international
              ? "internationalactive"
              : "international"
          }
        />
        <StyledButtons
          onClick={() => handleActive("domestic")}
          className={props.travelType.domestic ? "domesticactive" : "domestic"}
        />
      </div>

      {Object.values(props.travelType).some(Boolean) === true && (
        <button className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
      {Object.values(props.travelType).some(Boolean) !== true && (
        <button disabled className="continue" onClick={props.nextQuestion}>
          Continue
        </button>
      )}
    </TravelTypeStyles>
  );
}

export default TravelType;
