import {useState, useEffect} from 'react';
import Papa from 'papaparse';


const useCSVData = (filePath) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetch(filePath);
            if (!response.ok) {
                throw new Error('Network response not ok');
            }

            const reader = response.body?.getReader();
            if (!reader) {
                throw new Error('Failed to get reader from response body');
            }

            const result = await reader.read();
            const decoder = new TextDecoder('utf-8');
            const csv = decoder.decode(result.value);
            const parsedData = Papa.parse(csv, { header: true });
            setData(parsedData.data);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                console.log("Unknown error occurred.");
            }
        }
    };

    fetchData();
  }, [filePath]);

  return { data, error };
};

export default useCSVData;