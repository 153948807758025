import styled from "styled-components";
export const TripBudgetStyles = styled.div`
  .budget-middle-header {
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .budget-middle-text {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    text-align: center;
  }

  .custom-input {
    height: 50px;
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color: rgba(0, 153, 153, 0.2);
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    border-style: solid;
    border-radius: 10px;
  }

  .horizontal-slider {
    width: 40%;
    border-radius: 20px;
    height: 50px;
    display: inline-block;
    border: 1px solid #009999;
  }

  .slider-thumb {
    height: 25px !important;
    line-height: 25px;
    width: 25px !important;
    text-align: center;
    color: #009999;
    background-color: #009999;
    border-color: white;
    border-radius: 50%;
    cursor: grab;
    font-size: 0px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  }

  .slider-mark {
    width: 8px;
    height: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #009999;
    cursor: pointer;
    vertical-align: middle;
    margin: 0px calc(10px);
  }

  .slider-thumb.active {
    background-color: #009999;
  }

  .slider-track {
    position: relative;
    background: white;
  }

  .budget-top-header {
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    margin-top: 1rem;
  }

  .budget-top-text {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 400;
  }

  .small-slider-header {
    font-family: "Myriad Pro";
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    color: #005e84;
    text-align: center;
  }
`;
