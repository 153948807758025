import { useNavigate } from "react-router-dom";
import { HomeStyles } from "./home-styles";
import { Packages } from "./packages";
import { useEffect } from "react";


function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HomeStyles>
      <div className="home-banner mb-5">
        <div className="banner-container pb-5">
          <div className="banner-row test row ps-5">
            <div className="banner-col col ps-5">
              <h1 className="home-h1  text-start ps-5">
                <span className="stroke">Discover Your Next Adventure!</span>
              </h1>
              <h2 className="home-h2 text-start ps-5">
                Need help figuring out where you should travel?<br></br>
                Let Nina's algorithm find your next destination<br></br>
                based on your selected preferences.<br></br>
                Don't search for your next trip, Discover it!<br></br>
                <button
                  className="button"
                  onClick={() => {
                    navigate("/discover");
                  }}
                >
                  Read More
                </button>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <Packages></Packages>
      <div className="flight-banner mt-5">
        <div className="banner-container">
          <div className="banner-row test row">
            <div className="banner-col col">
              <h1 className="flight-h1 pt-5">
                <span className="stroke">
                  The average trip takes 4 hours to plan.<br></br>
                  This will take about 7 minutes.*<br></br>
                </span>
                <div className="button-container">
                  <button
                    className="button"
                    onClick={() => {
                      navigate("/survey");
                    }}
                  >
                    Nina Discover
                  </button>
                  <button
                    className="button"
                    onClick={() => {
                      navigate("/game");
                    }}
                  >
                    Destination Game
                  </button>
                </div>
              </h1>
            </div>
          </div>
        </div>
      </div>

    </HomeStyles>
  );
}

export default Home;
