import { Container, Row, Col } from "react-bootstrap";

const Privacy = () => {
  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col>
          <h2>Privacy Policy for Nina Trvl</h2>

          <p>
            This Privacy Policy describes how Nina Trvl ("we," "us," or "our")
            collects, uses, and protects the personal information you provide on
            our website. By accessing or using our website, you agree to the
            terms of this Privacy Policy.
          </p>

          <h3>Information Collection and Use</h3>

          <p>
            We may collect personal information from you when you voluntarily
            provide it to us through our website. This information may include
            your name, email address, and any other information you provide.
          </p>

          <p>We use this information to:</p>

          <ul>
            <li>Provide and personalize our services to you</li>
            <li>
              Communicate with you, respond to inquiries, and provide customer
              support
            </li>
            <li>Improve our website and enhance user experience</li>
            <li>Analyze website usage and trends using Google Analytics</li>
          </ul>

          <h3>GeoIP Data</h3>
          <p>
            To provide more accurate flight information and personalized
            services, we may collect GeoIP data from your device. GeoIP data
            allows us to determine your approximate location based on your IP
            address, which helps us tailor flight options specific to your
            location.
          </p>
          <p>We use GeoIP data for the following purposes:</p>
          <ul>
            <li>
              Providing flight options and travel deals that are relevant to
              your location
            </li>
            <li>Enhancing the accuracy of our flight search results</li>
            <li>
              Improving user experience by customizing content based on your
              location
            </li>
          </ul>
          <p>
            Your GeoIP data is collected and processed in compliance with
            applicable laws and regulations. We do not share your precise GeoIP
            information with third parties without obtaining your explicit
            consent.
          </p>
       

          <h3>Google Analytics</h3>

          <p>
            We use Google Analytics to collect and analyze data about how
            visitors use our website. Google Analytics uses cookies and similar
            technologies to collect information such as your IP address, device
            information, and website interactions. This information is used to
            analyze website traffic, track user engagement, and generate
            statistical reports.
          </p>

          <p>
            By using our website, you consent to the processing of data by
            Google Analytics as described in their{" "}
            <a
              rel="noreferrer"
              href="https://policies.google.com/privacy"
              target="_blank"
            >
              Privacy Policy
            </a>
            . You can learn more about how Google uses and protects data by
            visiting their Privacy Policy page.
          </p>

          <h3>Cookies</h3>

          <p>
            We use cookies and similar technologies to enhance your experience
            on our website. Cookies are small text files that are placed on your
            device by a web server and provide a way for the website to
            recognize you and remember your preferences.
          </p>

          <p>
            You can manage cookies through your web browser settings. Please
            note that disabling cookies may limit certain features and
            functionality on our website.
          </p>

          <h3>Information Sharing</h3>

          <p>
            We do not sell, trade, or otherwise transfer your personal
            information to third parties without your consent, except as
            described in this Privacy Policy or as required by law.
          </p>

          <p>
            We may share your information with trusted third-party service
            providers who assist us in operating our website, conducting
            business, or providing services to you. These service providers are
            obligated to keep your information confidential and use it only for
            the purposes specified by us.
          </p>

          <h3>Data Security</h3>

          <p>
            We implement reasonable security measures to protect the personal
            information we collect from unauthorized access, disclosure,
            alteration, or destruction. However, no method of transmission over
            the internet or electronic storage is 100% secure, and we cannot
            guarantee absolute security.
          </p>

          <h3>Links to Third-Party Websites</h3>

          <p>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of these
            third-party sites. We encourage you to review the privacy policies
            of those websites before providing any personal information.
          </p>

          <h3>Updates to this Privacy Policy</h3>

          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes will be effective immediately upon posting on our
            website. We encourage you to review this Privacy Policy periodically
            for any updates.
          </p>

          <h3>Contact Us</h3>

          <p>
            If you have any questions or concerns about this Privacy Policy or
            our practices, please contact us at{" "}
            <a href="mailto:dev@ninatrvl.com">dev@ninatrvl.com</a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Privacy;
