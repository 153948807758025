import styled from "styled-components";
import birthday from "../../../Assets/Images/birthday.svg";
import birthdayhover from "../../../Assets/Images/birthdayhover.svg";
import birthdayactivated from "../../../Assets/Images/birthdayactivated.svg";
import bachelorette from "../../../Assets/Images/bachelorette.svg";
import bachelorettehover from "../../../Assets/Images/bachelorettehover.svg";
import bacheloretteactivated from "../../../Assets/Images/bacheloretteactivated.svg";
import bachelor from "../../../Assets/Images/bachelor.svg";
import bachelorhover from "../../../Assets/Images/bachelorhover.svg";
import bacheloractivated from "../../../Assets/Images/bacheloractivated.svg";
import honeymoon from "../../../Assets/Images/honeymoon.svg";
import honeymoonhover from "../../../Assets/Images/honeymoonhover.svg";
import honeymoonactivated from "../../../Assets/Images/honeymoonactivated.svg";

export const StyledButtons = styled.button`
  border: none;
  background: none;
  width: 163px;
  height: 45px;
`;

export const WhatCelebratingStyles = styled.div`
  .box {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 40px 0;
    justify-content: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding-bottom: 5%;
  }

  .what-header {
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 48px;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .buttons {
    flex-direction: column;
    display: flex;
  }

  .what-text {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    font-size: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
  }

  .honeymoon {
    background-repeat: no-repeat;

    display: inline-block;
    flex-direction: column;
    background-image: url(${honeymoon});
    background-size: contain;
    width: 163px;
    height: 45px;
    border: none;
    cursor: pointer;
    &:hover {
      background-image: url(${honeymoonhover});
      background-size: contain;
      width: 163px;
      height: 45px;
    }
  }

  .honeymoonactive {
    background-repeat: no-repeat;
    justify-content: center;

    display: inline-block;
    flex-direction: column;
    background-image: url(${honeymoonactivated});
    background-size: contain;
    width: 163px;
    height: 45px;
    cursor: pointer;
  }

  .birthday {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${birthday});
    background-size: contain;
    width: 163px;
    height: 45px;
    border: none;
    cursor: pointer;
    &:hover {
      background-image: url(${birthdayhover});
      background-size: contain;
      width: 163px;
      height: 45px;
    }
  }

  .birthdayactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${birthdayactivated});
    background-size: contain;
    width: 163px;
    height: 45px;
    cursor: pointer;
  }
  .bachelor {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${bachelor});
    background-size: contain;
    width: 163px;
    height: 45px;
    border: none;
    cursor: pointer;
    &:hover {
      background-image: url(${bachelorhover});
      background-size: contain;
      width: 163px;
      height: 45px;
    }
  }

  .bacheloractive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${bacheloractivated});
    background-size: contain;
    width: 163px;
    height: 45px;
    cursor: pointer;
  }

  .bachelorette {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${bachelorette});
    background-size: contain;
    width: 163px;
    height: 45px;
    border: none;
    cursor: pointer;
    &:hover {
      background-image: url(${bachelorettehover});
      background-size: contain;
      width: 163px;
      height: 45px;
    }
  }

  .bacheloretteactive {
    background-repeat: no-repeat;
    justify-content: center;
    text-align: center;
    display: inline-block;
    flex-direction: column;
    background-image: url(${bacheloretteactivated});
    background-size: contain;
    width: 163px;
    height: 45px;
    cursor: pointer;
  }
`;
