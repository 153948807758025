import maui from "./photos/maui.jpg"
import bangkok from "./photos/bangkok.jpg"
import london from "./photos/london.jpg"
import galapagos from "./photos/galapagos.jpg"
import tulum from "./photos/tulum.jpg"
import kandy from "./photos/kandy.jpg"
import porto from "./photos/porto.jpg"
import kampot from "./photos/kampot.jpg"
import sanJose from "./photos/sanJose.jpg"
import sydney from "./photos/sydney.jpg"
import kuala from "./photos/kuala.jpg"
import jacksonHole from "./photos/jacksonHole.jpg"
import tokyo from "./photos/tokyo.jpg"
import johanseberg from "./photos/johanseberg.jpg"
import singapore from "./photos/singapore.jpg"
import paris from "./photos/paris.jpg"
import athens from "./photos/athens.jpg"
import barHarbor from "./photos/barHarbor.jpg"
import outerBanks from "./photos/outerBanks.jpg"
import jamaica from "./photos/jamaica.jpg"
import prague from "./photos/prague.jpg"
import ibiza from "./photos/ibiza.jpg"
import grandTetons from "./photos/grandTetons.jpg"
import yellowstone from "./photos/yellowstone.jpg"
import washingtonDC from "./photos/washingtonDC.jpg"
import rioDeJanerio from "./photos/rioDeJanerio.jpg"
import rome from "./photos/rome.jpg"
import maldives from "./photos/maldives.jpg"
import newYork from "./photos/newYork.jpg"
import sanFrancisco from "./photos/sanFrancisco.jpg"
import cairo from "./photos/cairo.jpg"
import aspen from "./photos/aspen.jpg"
import orlando from "./photos/orlando.jpg"
import shanghai from "./photos/shanghai.jpg"
import newOrleans from "./photos/newOrleans.jpg"
import bagan from "./photos/bagan.jpg"
import newZealand from "./photos/newZealand.jpg"
import jerusalem from "./photos/jerusalem.jpg"
import budapest from "./photos/budapest.jpg"
import amsterdam from "./photos/amsterdam.jpg"
import istanbul from "./photos/istanbul.jpg"
import barcelona from "./photos/barcelona.jpg"
import zurich from "./photos/zurich.jpg"
import virginIslands from "./photos/virginIslands.jpg"
import grandCanyon from "./photos/grandCanyon.jpg"
import delhi from "./photos/delhi.jpg"
export const DestinationRankings = {
      "Maui, HI": {
         "image": maui,
         "travel_type": "domestic",
         "cost_flight": 850,
         "cost_hotel": 225,
         "cost_food": 75,
         "cost_activities": 50,
         "cost_week": 5025,
         "celebration": 1,
         "relaxation": 1,
         "experience": 1,
         "family": 0.75,
         "beaches": 1,
         "nightlife": 0.2,
         "adventure": 1,
         "historical": 0.1,
         "culture": 0.75,
         "New_Zealand": 0.95,
         "Brazil": 0.2,
         "Italy": 0,
         "Dominican_Republic": 0.5,
         "Spain": 0,
         "Egypt": 0.1,
         "India": 0,
         "Thailand": 0.45,
         "Japan": 0.1,
         "nature_activities": 1,
         "gramworthy": 1,
         "winter_activities": 0,
         "food_experience": 0.5,
         "sightseeing": 0.85
      },
      "Bangkok, Thailand": {
         "image": bangkok,
         "travel_type": "international",
         "cost_flight": 1000,
         "cost_hotel": 60,
         "cost_food": 25,
         "cost_activities": 30,
         "cost_week": 3190,
         "celebration": 0.75,
         "relaxation": 0.5,
         "experience": 0.8,
         "family": 0.2,
         "beaches": 0.5,
         "nightlife": 1,
         "adventure": 0.8,
         "historical": 0.5,
         "culture": 0.65,
         "New_Zealand": 0.25,
         "Brazil": 0.4,
         "Italy": 0,
         "Dominican_Republic": 0.35,
         "Spain": 0.1,
         "Egypt": 0.05,
         "India": 0.35,
         "Thailand": 1,
         "Japan": 0.5,
         "nature_activities": 0.65,
         "gramworthy": 0.85,
         "winter_activities": 0,
         "food_experience": 0.75,
         "sightseeing": 0.65
      },
      "London, England": {
         "image": london,
         "travel_type": "international",
         "cost_flight": 600,
         "cost_hotel": 180,
         "cost_food": 100,
         "cost_activities": 100,
         "cost_week": 5260,
         "celebration": 0.75,
         "relaxation": 0.5,
         "experience": 0.5,
         "family": 0.25,
         "beaches": 0.5,
         "nightlife": 1,
         "adventure": 0.5,
         "historical": 0.8,
         "culture": 0.55,
         "New_Zealand": 0.05,
         "Brazil": 0.2,
         "Italy": 0.85,
         "Dominican_Republic": 0,
         "Spain": 0.5,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0.1,
         "nature_activities": 0.25,
         "gramworthy": 0.35,
         "winter_activities": 0.35,
         "food_experience": 0.35,
         "sightseeing": 0.75
      },
      "Galapagos Islands": {
         "image": galapagos,
         "travel_type": "international",
         "cost_flight": 600,
         "cost_hotel": 104,
         "cost_food": 27,
         "cost_activities": 40,
         "cost_week": 2866,
         "celebration": 0.4,
         "relaxation": 1,
         "experience": 0.85,
         "family": 0.5,
         "beaches": 1,
         "nightlife": 0.1,
         "adventure": 0.85,
         "historical": 0.3,
         "culture": 0.5,
         "New_Zealand": 0.5,
         "Brazil": 0.6,
         "Italy": 0.2,
         "Dominican_Republic": 0.25,
         "Spain": 0.25,
         "Egypt": 0,
         "India": 0.05,
         "Thailand": 0.2,
         "Japan": 0.2,
         "nature_activities": 0.85,
         "gramworthy": 0.95,
         "winter_activities": 0,
         "food_experience": 0.35,
         "sightseeing": 0.5
      },
      "Tulum, Mexico": {
         "image": tulum,
         "travel_type": "international",
         "cost_flight": 400,
         "cost_hotel": 80,
         "cost_food": 23,
         "cost_activities": 15,
         "cost_week": 1892,
         "celebration": 0.85,
         "relaxation": 1,
         "experience": 0.6,
         "family": 0.5,
         "beaches": 1,
         "nightlife": 0.25,
         "adventure": 0.6,
         "historical": 0.1,
         "culture": 0.5,
         "New_Zealand": 0.25,
         "Brazil": 0.5,
         "Italy": 0,
         "Dominican_Republic": 0.5,
         "Spain": 0.1,
         "Egypt": 0.25,
         "India": 0.15,
         "Thailand": 0.25,
         "Japan": 0.1,
         "nature_activities": 0.85,
         "gramworthy": 0.9,
         "winter_activities": 0,
         "food_experience": 0.5,
         "sightseeing": 0.75
      },
      "Kandy, Sri Lanka": {
         "image": kandy,
         "travel_type": "international",
         "cost_flight": 1100,
         "cost_hotel": 50,
         "cost_food": 15,
         "cost_activities": 10,
         "cost_week": 2900,
         "celebration": 0.75,
         "relaxation": 1,
         "experience": 0.9,
         "family": 0.2,
         "beaches": 1,
         "nightlife": 0.4,
         "adventure": 0.9,
         "historical": 0.3,
         "culture": 0.5,
         "New_Zealand": 0.1,
         "Brazil": 0.25,
         "Italy": 0.1,
         "Dominican_Republic": 0.4,
         "Spain": 0.1,
         "Egypt": 0.05,
         "India": 0.65,
         "Thailand": 0.65,
         "Japan": 0.25,
         "nature_activities": 0.75,
         "gramworthy": 0.75,
         "winter_activities": 0,
         "food_experience": 0.3,
         "sightseeing": 0.5
      },
      "Porto, Portugal": {
         "image": porto,
         "travel_type": "international",
         "cost_flight": 600,
         "cost_hotel": 120,
         "cost_food": 50,
         "cost_activities": 50,
         "cost_week": 3440,
         "celebration": 0.25,
         "relaxation": 0.5,
         "experience": 0.4,
         "family": 0.45,
         "beaches": 0.5,
         "nightlife": 0.65,
         "adventure": 0.4,
         "historical": 0.65,
         "culture": 0.75,
         "New_Zealand": 0.05,
         "Brazil": 0.25,
         "Italy": 0.9,
         "Dominican_Republic": 0.1,
         "Spain": 0.85,
         "Egypt": 0.05,
         "India": 0,
         "Thailand": 0.1,
         "Japan": 0.05,
         "nature_activities": 0.3,
         "gramworthy": 0.5,
         "winter_activities": 0.05,
         "food_experience": 0.5,
         "sightseeing": 0.25
      },
      "Kampot, Cambodia": {
         "image": kampot,
         "travel_type": "international",
         "cost_flight": 1400,
         "cost_hotel": 25,
         "cost_food": 15,
         "cost_activities": 3,
         "cost_week": 3227,
         "celebration": 0.65,
         "relaxation": 0.65,
         "experience": 0.9,
         "family": 0.25,
         "beaches": 0.65,
         "nightlife": 0.5,
         "adventure": 0.9,
         "historical": 0.3,
         "culture": 0.75,
         "New_Zealand": 0.1,
         "Brazil": 0.4,
         "Italy": 0.15,
         "Dominican_Republic": 0.1,
         "Spain": 0.2,
         "Egypt": 0.1,
         "India": 0.5,
         "Thailand": 0.65,
         "Japan": 0.25,
         "nature_activities": 0.35,
         "gramworthy": 0.65,
         "winter_activities": 0,
         "food_experience": 0.45,
         "sightseeing": 0.5
      },
      "San Jose, Costa Rica": {
         "image": sanJose,
         "travel_type": "international",
         "cost_flight": 450,
         "cost_hotel": 73,
         "cost_food": 15,
         "cost_activities": 20,
         "cost_week": 1901,
         "celebration": 0.6,
         "relaxation": 1,
         "experience": 0.8,
         "family": 0.45,
         "beaches": 1,
         "nightlife": 0.25,
         "adventure": 0.8,
         "historical": 0.1,
         "culture": 0.6,
         "New_Zealand": 0.25,
         "Brazil": 0.5,
         "Italy": 0,
         "Dominican_Republic": 0.6,
         "Spain": 0.35,
         "Egypt": 0,
         "India": 0.05,
         "Thailand": 0.2,
         "Japan": 0.1,
         "nature_activities": 0.85,
         "gramworthy": 0.75,
         "winter_activities": 0,
         "food_experience": 0.25,
         "sightseeing": 0.75
      },
      "Sydney, Australia": {
         "image": sydney,
         "travel_type": "international",
         "cost_flight": 1400,
         "cost_hotel": 132,
         "cost_food": 66,
         "cost_activities": 115,
         "cost_week": 6258,
         "celebration": 0.25,
         "relaxation": 1,
         "experience": 0.9,
         "family": 0.6,
         "beaches": 1,
         "nightlife": 0.1,
         "adventure": 0.9,
         "historical": 0.3,
         "culture": 0.6,
         "New_Zealand": 0.9,
         "Brazil": 0.5,
         "Italy": 0,
         "Dominican_Republic": 0.5,
         "Spain": 0.1,
         "Egypt": 0.15,
         "India": 0.05,
         "Thailand": 0.15,
         "Japan": 0.1,
         "nature_activities": 0.75,
         "gramworthy": 0.65,
         "winter_activities": 0.2,
         "food_experience": 0.35,
         "sightseeing": 0.75
      },
      "Kuala Lumpur, Malaysia": {
         "image": kuala,
         "travel_type": "international",
         "cost_flight": 975,
         "cost_hotel": 25,
         "cost_food": 11,
         "cost_activities": 5,
         "cost_week": 2349,
         "celebration": 0.4,
         "relaxation": 1,
         "experience": 0.85,
         "family": 0.35,
         "beaches": 1,
         "nightlife": 0.4,
         "adventure": 0.85,
         "historical": 0.1,
         "culture": 0.75,
         "New_Zealand": 0.25,
         "Brazil": 0.3,
         "Italy": 0.1,
         "Dominican_Republic": 0.65,
         "Spain": 0.1,
         "Egypt": 0,
         "India": 0.35,
         "Thailand": 0.8,
         "Japan": 0.65,
         "nature_activities": 0.75,
         "gramworthy": 0.55,
         "winter_activities": 0,
         "food_experience": 0.65,
         "sightseeing": 0.65
      },
      "Jackson Hole, WY": {
         "image": jacksonHole,
         "travel_type": "domestic",
         "cost_flight": 450,
         "cost_hotel": 121,
         "cost_food": 60,
         "cost_activities": 50,
         "cost_week": 3287,
         "celebration": 0.2,
         "relaxation": 0,
         "experience": 0.85,
         "family": 0.25,
         "beaches": 0,
         "nightlife": 0.2,
         "adventure": 0.85,
         "historical": 0.1,
         "culture": 0.1,
         "New_Zealand": 0.15,
         "Brazil": 0.1,
         "Italy": 0.1,
         "Dominican_Republic": 0,
         "Spain": 0,
         "Egypt": 0.15,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.7,
         "gramworthy": 0.75,
         "winter_activities": 1,
         "food_experience": 0.1,
         "sightseeing": 0.5
      },
      "Tokyo, Japan": {
         "image": tokyo,
         "travel_type": "international",
         "cost_flight": 1800,
         "cost_hotel": 147,
         "cost_food": 39,
         "cost_activities": 20,
         "cost_week": 5455,
         "celebration": 0.75,
         "relaxation": 1,
         "experience": 0.7,
         "family": 0.85,
         "beaches": 1,
         "nightlife": 1,
         "adventure": 0.7,
         "historical": 0.5,
         "culture": 0.75,
         "New_Zealand": 0.05,
         "Brazil": 0.3,
         "Italy": 0.1,
         "Dominican_Republic": 0.2,
         "Spain": 0,
         "Egypt": 0,
         "India": 0.25,
         "Thailand": 0.85,
         "Japan": 1,
         "nature_activities": 0.35,
         "gramworthy": 0.95,
         "winter_activities": 0.1,
         "food_experience": 0.75,
         "sightseeing": 0.95
      },
      "Johanseberg, South Africa": {
         "image": johanseberg,
         "travel_type": "international",
         "cost_flight": 1800,
         "cost_hotel": 89,
         "cost_food": 19,
         "cost_activities": 10,
         "cost_week": 4629,
         "celebration": 0.65,
         "relaxation": 0.9,
         "experience": 0.75,
         "family": 0.5,
         "beaches": 0.9,
         "nightlife": 0.4,
         "adventure": 0.75,
         "historical": 0.25,
         "culture": 0.65,
         "New_Zealand": 0.1,
         "Brazil": 0.3,
         "Italy": 0.3,
         "Dominican_Republic": 0.1,
         "Spain": 0,
         "Egypt": 0.35,
         "India": 0.1,
         "Thailand": 0.2,
         "Japan": 0.2,
         "nature_activities": 0.85,
         "gramworthy": 0.65,
         "winter_activities": 0,
         "food_experience": 0.65,
         "sightseeing": 0.9
      },
      "Singapore": {
         "image": singapore,
         "travel_type": "international",
         "cost_flight": 2000,
         "cost_hotel": 165,
         "cost_food": 66,
         "cost_activities": 60,
         "cost_week": 6919,
         "celebration": 0.7,
         "relaxation": 1,
         "experience": 0.8,
         "family": 0.5,
         "beaches": 1,
         "nightlife": 0.7,
         "adventure": 0.8,
         "historical": 0.4,
         "culture": 0.65,
         "New_Zealand": 0.1,
         "Brazil": 0.4,
         "Italy": 0,
         "Dominican_Republic": 0.2,
         "Spain": 0.1,
         "Egypt": 0.05,
         "India": 0.25,
         "Thailand": 0.65,
         "Japan": 0.85,
         "nature_activities": 0.25,
         "gramworthy": 0.85,
         "winter_activities": 0,
         "food_experience": 0.95,
         "sightseeing": 0.85
      },
      "Paris, France": {
         "image": paris,
         "travel_type": "international",
         "cost_flight": 700,
         "cost_hotel": 120,
         "cost_food": 68,
         "cost_activities": 65,
         "cost_week": 4102,
         "celebration": 1,
         "relaxation": 0,
         "experience": 0.2,
         "family": 0.75,
         "beaches": 0,
         "nightlife": 1,
         "adventure": 0.2,
         "historical": 0.5,
         "culture": 0.9,
         "New_Zealand": 0.05,
         "Brazil": 0.1,
         "Italy": 1,
         "Dominican_Republic": 0,
         "Spain": 0.5,
         "Egypt": 0.1,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.2,
         "gramworthy": 1,
         "winter_activities": 0,
         "food_experience": 1,
         "sightseeing": 1
      },
      "Athens, Greece": {
         "image": athens,
         "travel_type": "international",
         "cost_flight": 550,
         "cost_hotel": 100,
         "cost_food": 33,
         "cost_activities": 32,
         "cost_week": 2710,
         "celebration": 0.8,
         "relaxation": 0.9,
         "experience": 0.2,
         "family": 0.75,
         "beaches": 0.9,
         "nightlife": 0.8,
         "adventure": 0.2,
         "historical": 1,
         "culture": 0.8,
         "New_Zealand": 0.1,
         "Brazil": 0.2,
         "Italy": 0.9,
         "Dominican_Republic": 0.1,
         "Spain": 0.35,
         "Egypt": 0.15,
         "India": 0.05,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.35,
         "gramworthy": 0.85,
         "winter_activities": 0,
         "food_experience": 0.75,
         "sightseeing": 0.95
      },
      "Bar Harbor, ME": {
         "image": barHarbor,
         "travel_type": "domestic",
         "cost_flight": 400,
         "cost_hotel": 188,
         "cost_food": 45,
         "cost_activities": 28,
         "cost_week": 3138,
         "celebration": 0.3,
         "relaxation": 0.2,
         "experience": 0.75,
         "family": 0.8,
         "beaches": 0.2,
         "nightlife": 0.2,
         "adventure": 0.75,
         "historical": 0.1,
         "culture": 0.1,
         "New_Zealand": 0.05,
         "Brazil": 0.25,
         "Italy": 0.2,
         "Dominican_Republic": 0,
         "Spain": 0,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.75,
         "gramworthy": 0.5,
         "winter_activities": 0.65,
         "food_experience": 0.25,
         "sightseeing": 0.65
      },
      "Outer Banks, NC": {
         "image": outerBanks,
         "travel_type": "domestic",
         "cost_flight": 400,
         "cost_hotel": 128,
         "cost_food": 33,
         "cost_activities": 32,
         "cost_week": 2606,
         "celebration": 0.5,
         "relaxation": 0.85,
         "experience": 0.2,
         "family": 0.85,
         "beaches": 0.85,
         "nightlife": 0.2,
         "adventure": 0.2,
         "historical": 0.1,
         "culture": 0.1,
         "New_Zealand": 0.15,
         "Brazil": 0.3,
         "Italy": 0.25,
         "Dominican_Republic": 0.2,
         "Spain": 0,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.65,
         "gramworthy": 0.5,
         "winter_activities": 0,
         "food_experience": 0.1,
         "sightseeing": 0.35
      },
      "Jamaica": {
         "image": jamaica,
         "travel_type": "international",
         "cost_flight": 500,
         "cost_hotel": 132,
         "cost_food": 20,
         "cost_activities": 10,
         "cost_week": 2344,
         "celebration": 0.75,
         "relaxation": 0.9,
         "experience": 0.85,
         "family": 0.9,
         "beaches": 0.9,
         "nightlife": 0.4,
         "adventure": 0.5,
         "historical": 0.15,
         "culture": 0.6,
         "New_Zealand": 0.35,
         "Brazil": 0.5,
         "Italy": 0,
         "Dominican_Republic": 0.85,
         "Spain": 0,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.65,
         "gramworthy": 0.75,
         "winter_activities": 0,
         "food_experience": 0.35,
         "sightseeing": 0.3
      },
      "Prague, Czech Republic": {
         "image": prague,
         "travel_type": "international",
         "cost_flight": 800,
         "cost_hotel": 100,
         "cost_food": 35,
         "cost_activities": 20,
         "cost_week": 3070,
         "celebration": 0.8,
         "relaxation": 0.3,
         "experience": 0.85,
         "family": 0.5,
         "beaches": 0.2,
         "nightlife": 0.8,
         "adventure": 0.2,
         "historical": 0.5,
         "culture": 0.65,
         "New_Zealand": 0.05,
         "Brazil": 0.1,
         "Italy": 1,
         "Dominican_Republic": 0,
         "Spain": 0.35,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.15,
         "gramworthy": 0.65,
         "winter_activities": 0.15,
         "food_experience": 0.7,
         "sightseeing": 0.7
      },
      "Ibiza, Spain": {
         "image": ibiza,
         "travel_type": "international",
         "cost_flight": 600,
         "cost_hotel": 130,
         "cost_food": 30,
         "cost_activities": 55,
         "cost_week": 3300,
         "celebration": 1,
         "relaxation": 1,
         "experience": 0.6,
         "family": 0.2,
         "beaches": 1,
         "nightlife": 1,
         "adventure": 0.2,
         "historical": 0,
         "culture": 0.55,
         "New_Zealand": 0.2,
         "Brazil": 0.4,
         "Italy": 0.5,
         "Dominican_Republic": 0.5,
         "Spain": 0.9,
         "Egypt": 0,
         "India": 0.05,
         "Thailand": 0.1,
         "Japan": 0,
         "nature_activities": 0.5,
         "gramworthy": 0.95,
         "winter_activities": 0,
         "food_experience": 0.7,
         "sightseeing": 0.5
      },
      "Grand Tetons National Park": {
         "image": grandTetons,
         "travel_type": "domestic",
         "cost_flight": 400,
         "cost_hotel": 127,
         "cost_food": 37,
         "cost_activities": 40,
         "cost_week": 2767,
         "celebration": 0.2,
         "relaxation": 0.2,
         "experience": 1,
         "family": 0.85,
         "beaches": 0,
         "nightlife": 0.2,
         "adventure": 1,
         "historical": 0.1,
         "culture": 0.1,
         "New_Zealand": 0.15,
         "Brazil": 0.05,
         "Italy": 0.05,
         "Dominican_Republic": 0,
         "Spain": 0,
         "Egypt": 0.25,
         "India": 0.1,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 1,
         "gramworthy": 0.85,
         "winter_activities": 0.65,
         "food_experience": 0.1,
         "sightseeing": 0.6
      },
      "Yellowstone": {
         "image": yellowstone,
         "travel_type": "domestic",
         "cost_flight": 400,
         "cost_hotel": 118,
         "cost_food": 37,
         "cost_activities": 30,
         "cost_week": 2564,
         "celebration": 0.2,
         "relaxation": 0.2,
         "experience": 1,
         "family": 1,
         "beaches": 0,
         "nightlife": 0.2,
         "adventure": 1,
         "historical": 0.2,
         "culture": 0.2,
         "New_Zealand": 0.15,
         "Brazil": 0.05,
         "Italy": 0.1,
         "Dominican_Republic": 0,
         "Spain": 0,
         "Egypt": 0.25,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 1,
         "gramworthy": 0.85,
         "winter_activities": 0.5,
         "food_experience": 0.05,
         "sightseeing": 0.75
      },
      "Washington, D.C.": {
         "image": washingtonDC,
         "travel_type": "domestic",
         "cost_flight": 250,
         "cost_hotel": 181,
         "cost_food": 30,
         "cost_activities": 37,
         "cost_week": 2705,
         "celebration": 0.7,
         "relaxation": 0,
         "experience": 0.1,
         "family": 0.75,
         "beaches": 0,
         "nightlife": 0.7,
         "adventure": 0.1,
         "historical": 0.75,
         "culture": 0.7,
         "New_Zealand": 0.05,
         "Brazil": 0.05,
         "Italy": 0.5,
         "Dominican_Republic": 0,
         "Spain": 0,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.1,
         "gramworthy": 0.3,
         "winter_activities": 0.1,
         "food_experience": 0.25,
         "sightseeing": 0.65
      },
      "Rio De Janerio": {
         "image": rioDeJanerio,
         "travel_type": "international",
         "cost_flight": 800,
         "cost_hotel": 108,
         "cost_food": 17,
         "cost_activities": 10,
         "cost_week": 2734,
         "celebration": 1,
         "relaxation": 1,
         "experience": 0.2,
         "family": 0.5,
         "beaches": 1,
         "nightlife": 1,
         "adventure": 0.2,
         "historical": 0.2,
         "culture": 0.8,
         "New_Zealand": 0.15,
         "Brazil": 1,
         "Italy": 0.2,
         "Dominican_Republic": 0.5,
         "Spain": 0.5,
         "Egypt": 0,
         "India": 0.1,
         "Thailand": 0.15,
         "Japan": 0.1,
         "nature_activities": 0.6,
         "gramworthy": 0.8,
         "winter_activities": 0.05,
         "food_experience": 0.5,
         "sightseeing": 0.5
      },
      "Rome, Italy": {
         "image": rome,
         "travel_type": "international",
         "cost_flight": 500,
         "cost_hotel": 145,
         "cost_food": 45,
         "cost_activities": 65,
         "cost_week": 3555,
         "celebration": 0.7,
         "relaxation": 0.65,
         "experience": 0.5,
         "family": 0.75,
         "beaches": 0,
         "nightlife": 0.5,
         "adventure": 0.5,
         "historical": 1,
         "culture": 0.9,
         "New_Zealand": 0.1,
         "Brazil": 0.2,
         "Italy": 1,
         "Dominican_Republic": 0.1,
         "Spain": 0.75,
         "Egypt": 0.1,
         "India": 0.05,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.1,
         "gramworthy": 0.85,
         "winter_activities": 0,
         "food_experience": 0.9,
         "sightseeing": 0.95
      },
      "Maldives": {
         "image": maldives,
         "travel_type": "international",
         "cost_flight": 1400,
         "cost_hotel": 188,
         "cost_food": 62,
         "cost_activities": 50,
         "cost_week": 5684,
         "celebration": 1,
         "relaxation": 1,
         "experience": 0.5,
         "family": 0.2,
         "beaches": 1,
         "nightlife": 0.3,
         "adventure": 0.5,
         "historical": 0.2,
         "culture": 0.4,
         "New_Zealand": 0.4,
         "Brazil": 0.35,
         "Italy": 0.2,
         "Dominican_Republic": 0.75,
         "Spain": 0.2,
         "Egypt": 0.05,
         "India": 0.35,
         "Thailand": 0.35,
         "Japan": 0.2,
         "nature_activities": 0.5,
         "gramworthy": 1,
         "winter_activities": 0,
         "food_experience": 0.5,
         "sightseeing": 0.5
      },
      "New York City, United States of America": {
         "image": newYork,
         "travel_type": "domestic",
         "cost_flight": 400,
         "cost_hotel": 385,
         "cost_food": 60,
         "cost_activities": 25,
         "cost_week": 4685,
         "celebration": 1,
         "relaxation": 0.5,
         "experience": 0.1,
         "family": 0.75,
         "beaches": 0.5,
         "nightlife": 1,
         "adventure": 0.1,
         "historical": 0.6,
         "culture": 1,
         "New_Zealand": 0,
         "Brazil": 0.4,
         "Italy": 0.5,
         "Dominican_Republic": 0,
         "Spain": 0,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0,
         "gramworthy": 0.85,
         "winter_activities": 0.6,
         "food_experience": 0.75,
         "sightseeing": 0.9
      },
      "San Francisco, CA": {
         "image": sanFrancisco,
         "travel_type": "domestic",
         "cost_flight": 400,
         "cost_hotel": 212,
         "cost_food": 32,
         "cost_activities": 20,
         "cost_week": 3012,
         "celebration": 0.5,
         "relaxation": 0.3,
         "experience": 0.5,
         "family": 0.65,
         "beaches": 0.3,
         "nightlife": 0.5,
         "adventure": 0.5,
         "historical": 0.1,
         "culture": 0.6,
         "New_Zealand": 0.05,
         "Brazil": 0.35,
         "Italy": 0.6,
         "Dominican_Republic": 0.1,
         "Spain": 0,
         "Egypt": 0.1,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.65,
         "gramworthy": 0.65,
         "winter_activities": 0,
         "food_experience": 0.8,
         "sightseeing": 0.75
      },
      "Cairo, Egypt": {
         "image": cairo,
         "travel_type": "international",
         "cost_flight": 800,
         "cost_hotel": 46,
         "cost_food": 10,
         "cost_activities": 5,
         "cost_week": 2132,
         "celebration": 0.3,
         "relaxation": 0.35,
         "experience": 0.8,
         "family": 0.75,
         "beaches": 0,
         "nightlife": 0.3,
         "adventure": 0.8,
         "historical": 1,
         "culture": 0.85,
         "New_Zealand": 0.05,
         "Brazil": 0.1,
         "Italy": 0.1,
         "Dominican_Republic": 0,
         "Spain": 0.1,
         "Egypt": 1,
         "India": 0.35,
         "Thailand": 0.2,
         "Japan": 0.2,
         "nature_activities": 0.15,
         "gramworthy": 1,
         "winter_activities": 0,
         "food_experience": 0.5,
         "sightseeing": 0.95
      },
      "Aspen, CO": {
         "image": aspen,
         "travel_type": "domestic",
         "cost_flight": 450,
         "cost_hotel": 271,
         "cost_food": 68,
         "cost_activities": 45,
         "cost_week": 4379,
         "celebration": 0.35,
         "relaxation": 0.5,
         "experience": 0.7,
         "family": 0.85,
         "beaches": 0,
         "nightlife": 0.2,
         "adventure": 0.7,
         "historical": 0.1,
         "culture": 0.1,
         "New_Zealand": 0.05,
         "Brazil": 0,
         "Italy": 0,
         "Dominican_Republic": 0,
         "Spain": 0,
         "Egypt": 0.1,
         "India": 0,
         "Thailand": 0,
         "Japan": 0.15,
         "nature_activities": 0.75,
         "gramworthy": 0.75,
         "winter_activities": 1,
         "food_experience": 0.1,
         "sightseeing": 0.65
      },
      "Orlando, FL": {
         "image": orlando,
         "travel_type": "domestic",
         "cost_flight": 300,
         "cost_hotel": 225,
         "cost_food": 50,
         "cost_activities": 100,
         "cost_week": 4275,
         "celebration": 0.5,
         "relaxation": 0.6,
         "experience": 0.85,
         "family": 1,
         "beaches": 0.5,
         "nightlife": 0.35,
         "adventure": 0,
         "historical": 0.05,
         "culture": 0.4,
         "New_Zealand": 0.05,
         "Brazil": 0.5,
         "Italy": 0.25,
         "Dominican_Republic": 0.1,
         "Spain": 0.1,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.2,
         "gramworthy": 0.5,
         "winter_activities": 0,
         "food_experience": 0.15,
         "sightseeing": 0.75
      },
      "Shanghai, China": {
         "image": shanghai,
         "travel_type": "international",
         "cost_flight": 1500,
         "cost_hotel": 140,
         "cost_food": 19,
         "cost_activities": 10,
         "cost_week": 4386,
         "celebration": 0.5,
         "relaxation": 0.45,
         "experience": 0.85,
         "family": 0.35,
         "beaches": 0.3,
         "nightlife": 0.7,
         "adventure": 0.6,
         "historical": 0.3,
         "culture": 0.85,
         "New_Zealand": 0.05,
         "Brazil": 0.45,
         "Italy": 0.3,
         "Dominican_Republic": 0.1,
         "Spain": 0,
         "Egypt": 0,
         "India": 0.25,
         "Thailand": 0.75,
         "Japan": 0.85,
         "nature_activities": 0.1,
         "gramworthy": 0.65,
         "winter_activities": 0,
         "food_experience": 0.5,
         "sightseeing": 0.75
      },
      "New Orleans, LA": {
         "image": newOrleans,
         "travel_type": "domestic",
         "cost_flight": 350,
         "cost_hotel": 200,
         "cost_food": 36,
         "cost_activities": 40,
         "cost_week": 3164,
         "celebration": 1,
         "relaxation": 0.2,
         "experience": 1,
         "family": 0.4,
         "beaches": 0.2,
         "nightlife": 1,
         "adventure": 0.1,
         "historical": 0.25,
         "culture": 0.75,
         "New_Zealand": 0.2,
         "Brazil": 0.2,
         "Italy": 0.15,
         "Dominican_Republic": 0.2,
         "Spain": 0,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.15,
         "gramworthy": 0.5,
         "winter_activities": 0,
         "food_experience": 0.65,
         "sightseeing": 0.6
      },
      "Bagan, Myanmar": {
         "image": bagan,
         "travel_type": "international",
         "cost_flight": 1400,
         "cost_hotel": 19,
         "cost_food": 7,
         "cost_activities": 5,
         "cost_week": 3101,
         "celebration": 0.75,
         "relaxation": 0.85,
         "experience": 0.7,
         "family": 0.2,
         "beaches": 0.85,
         "nightlife": 0.2,
         "adventure": 0.9,
         "historical": 0.3,
         "culture": 0.6,
         "New_Zealand": 0.35,
         "Brazil": 0.4,
         "Italy": 0.2,
         "Dominican_Republic": 0.35,
         "Spain": 0.2,
         "Egypt": 0,
         "India": 0.5,
         "Thailand": 0.6,
         "Japan": 0.4,
         "nature_activities": 0.5,
         "gramworthy": 0.6,
         "winter_activities": 0,
         "food_experience": 0.55,
         "sightseeing": 0.5
      },
      "New Zealand": {
         "image": newZealand,
         "travel_type": "international",
         "cost_flight": 1500,
         "cost_hotel": 107,
         "cost_food": 23,
         "cost_activities": 30,
         "cost_week": 4491,
         "celebration": 0.6,
         "relaxation": 0.75,
         "experience": 0.8,
         "family": 0.6,
         "beaches": 0.75,
         "nightlife": 0.6,
         "adventure": 0.8,
         "historical": 0.2,
         "culture": 0.5,
         "New_Zealand": 1,
         "Brazil": 0.5,
         "Italy": 0.5,
         "Dominican_Republic": 0.35,
         "Spain": 0.1,
         "Egypt": 0,
         "India": 0.05,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.85,
         "gramworthy": 0.95,
         "winter_activities": 0,
         "food_experience": 0.5,
         "sightseeing": 0.85
      },
      "Jerusalem, Israel": {
         "image": jerusalem,
         "travel_type": "international",
         "cost_flight": 800,
         "cost_hotel": 88,
         "cost_food": 26,
         "cost_activities": 35,
         "cost_week": 3070,
         "celebration": 0.1,
         "relaxation": 0.15,
         "experience": 0.85,
         "family": 0.75,
         "beaches": 0.15,
         "nightlife": 0.1,
         "adventure": 0.5,
         "historical": 1,
         "culture": 0.85,
         "New_Zealand": 0.05,
         "Brazil": 0.4,
         "Italy": 0.85,
         "Dominican_Republic": 0,
         "Spain": 0.1,
         "Egypt": 0.35,
         "India": 0.25,
         "Thailand": 0.15,
         "Japan": 0.15,
         "nature_activities": 0.45,
         "gramworthy": 0.65,
         "winter_activities": 0,
         "food_experience": 0.75,
         "sightseeing": 0.8
      },
      "Budapest, Hungary": {
         "image": budapest,
         "travel_type": "international",
         "cost_flight": 600,
         "cost_hotel": 68,
         "cost_food": 15,
         "cost_activities": 25,
         "cost_week": 2236,
         "celebration": 0.5,
         "relaxation": 0.3,
         "experience": 0.8,
         "family": 0.5,
         "beaches": 0.5,
         "nightlife": 0.85,
         "adventure": 0.8,
         "historical": 0.75,
         "culture": 0.95,
         "New_Zealand": 0.05,
         "Brazil": 0.5,
         "Italy": 0.8,
         "Dominican_Republic": 0,
         "Spain": 0.35,
         "Egypt": 0.2,
         "India": 0.1,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.1,
         "gramworthy": 0.8,
         "winter_activities": 0.35,
         "food_experience": 0.65,
         "sightseeing": 0.65
      },
      "Amsterdam, Holland": {
         "image": amsterdam,
         "travel_type": "international",
         "cost_flight": 500,
         "cost_hotel": 154,
         "cost_food": 35,
         "cost_activities": 50,
         "cost_week": 3268,
         "celebration": 0.85,
         "relaxation": 0.25,
         "experience": 1,
         "family": 0.35,
         "beaches": 0.15,
         "nightlife": 1,
         "adventure": 1,
         "historical": 0.85,
         "culture": 0.85,
         "New_Zealand": 0.05,
         "Brazil": 0.2,
         "Italy": 0.85,
         "Dominican_Republic": 0,
         "Spain": 0.6,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.25,
         "gramworthy": 0.8,
         "winter_activities": 0,
         "food_experience": 0.75,
         "sightseeing": 0.85
      },
      "Istanbul, Turkey": {
         "image": istanbul,
         "travel_type": "international",
         "cost_flight": 700,
         "cost_hotel": 54,
         "cost_food": 11,
         "cost_activities": 10,
         "cost_week": 2072,
         "celebration": 0.35,
         "relaxation": 0.2,
         "experience": 0.85,
         "family": 0.35,
         "beaches": 0.2,
         "nightlife": 0.5,
         "adventure": 0.5,
         "historical": 0.85,
         "culture": 0.75,
         "New_Zealand": 0.1,
         "Brazil": 0.3,
         "Italy": 0.6,
         "Dominican_Republic": 0,
         "Spain": 0.35,
         "Egypt": 0.25,
         "India": 0.1,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.15,
         "gramworthy": 0.9,
         "winter_activities": 0.1,
         "food_experience": 0.25,
         "sightseeing": 0.75
      },
      "Barcelona, Spain": {
         "image": barcelona,
         "travel_type": "international",
         "cost_flight": 500,
         "cost_hotel": 146,
         "cost_food": 26,
         "cost_activities": 50,
         "cost_week": 3086,
         "celebration": 0.85,
         "relaxation": 0.65,
         "experience": 0.85,
         "family": 0.7,
         "beaches": 0.75,
         "nightlife": 1,
         "adventure": 0.8,
         "historical": 0.75,
         "culture": 0.8,
         "New_Zealand": 0.2,
         "Brazil": 0.2,
         "Italy": 0.9,
         "Dominican_Republic": 0.1,
         "Spain": 1,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.15,
         "gramworthy": 0.75,
         "winter_activities": 0,
         "food_experience": 0.85,
         "sightseeing": 0.9
      },
      "Zurich, Switzerland": {
         "image": zurich,
         "travel_type": "international",
         "cost_flight": 500,
         "cost_hotel": 243,
         "cost_food": 45,
         "cost_activities": 75,
         "cost_week": 4381,
         "celebration": 0.6,
         "relaxation": 0.35,
         "experience": 0.8,
         "family": 0.7,
         "beaches": 0,
         "nightlife": 0.6,
         "adventure": 0.8,
         "historical": 0.8,
         "culture": 0.8,
         "New_Zealand": 0.05,
         "Brazil": 0.2,
         "Italy": 0.9,
         "Dominican_Republic": 0,
         "Spain": 0.65,
         "Egypt": 0,
         "India": 0,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 0.25,
         "gramworthy": 0.85,
         "winter_activities": 0.65,
         "food_experience": 0.85,
         "sightseeing": 0.75
      },
      "Virgin Islands": {
         "image": virginIslands,
         "travel_type": "domestic",
         "cost_flight": 400,
         "cost_hotel": 209,
         "cost_food": 41,
         "cost_activities": 75,
         "cost_week": 3887,
         "celebration": 1,
         "relaxation": 0.9,
         "experience": 0.65,
         "family": 0.8,
         "beaches": 0.9,
         "nightlife": 0.25,
         "adventure": 0.65,
         "historical": 0.3,
         "culture": 0.5,
         "New_Zealand": 0.35,
         "Brazil": 0.25,
         "Italy": 0.25,
         "Dominican_Republic": 0.75,
         "Spain": 0,
         "Egypt": 0,
         "India": 0.1,
         "Thailand": 0.25,
         "Japan": 0.1,
         "nature_activities": 0.75,
         "gramworthy": 0.95,
         "winter_activities": 0,
         "food_experience": 0.45,
         "sightseeing": 0.25
      },
      "Grand Canyon": {
         "image": grandCanyon,
         "travel_type": "domestic",
         "cost_flight": 400,
         "cost_hotel": 142,
         "cost_food": 39,
         "cost_activities": 40,
         "cost_week": 2900,
         "celebration": 0.3,
         "relaxation": 0.25,
         "experience": 1,
         "family": 1,
         "beaches": 0,
         "nightlife": 0.1,
         "adventure": 1,
         "historical": 0.65,
         "culture": 0,
         "New_Zealand": 0.1,
         "Brazil": 0,
         "Italy": 0.35,
         "Dominican_Republic": 0,
         "Spain": 0,
         "Egypt": 0.25,
         "India": 0.15,
         "Thailand": 0,
         "Japan": 0,
         "nature_activities": 1,
         "gramworthy": 0.95,
         "winter_activities": 0,
         "food_experience": 0.05,
         "sightseeing": 1
      },
      "Delhi, India": {
         "image": delhi,
         "travel_type": "international",
         "cost_flight": 700,
         "cost_hotel": 53,
         "cost_food": 11,
         "cost_activities": 5,
         "cost_week": 1995,
         "celebration": 0.35,
         "relaxation": 0.25,
         "experience": 0.75,
         "family": 0.3,
         "beaches": 0,
         "nightlife": 0.5,
         "adventure": 0.75,
         "historical": 0.5,
         "culture": 0.9,
         "New_Zealand": 0,
         "Brazil": 0.1,
         "Italy": 0.1,
         "Dominican_Republic": 0.1,
         "Spain": 0.1,
         "Egypt": 0.25,
         "India": 1,
         "Thailand": 0.5,
         "Japan": 0.35,
         "nature_activities": 0.1,
         "gramworthy": 0.4,
         "winter_activities": 0,
         "food_experience": 0.6,
         "sightseeing": 0.5
      }
   
};