import brazil from "../../../Assets/Images/brazil.svg";
import italy from "../../../Assets/Images/italy.svg";
import newZealand from "../../../Assets/Images/new-zealand.svg";
import spain from "../../../Assets/Images/spain.svg";
import egypt from "../../../Assets/Images/egypt.svg";
import thailand from "../../../Assets/Images/thailand.svg";
import japan from "../../../Assets/Images/japan.svg";
import india from "../../../Assets/Images/india.svg";
import dominicanRepublic from "../../../Assets/Images/dominican-republic.svg";

export const Countries = [
    {
      id: 1,
      image: brazil,
      country: "Brazil",
    },
    {
      id: 2,
      image: newZealand,
      country: "New_Zealand",
    },
    {
      id: 3,
      image: italy,
      country: "Italy",
    },
    {
      id: 4,
      image: dominicanRepublic,
      country: "Dominican_Republic",
    },
    {
      id: 5,
      image: spain,
      country: "Spain",
    },
    {
      id: 6,
      image: egypt,
      country: "Egypt",
    },
    {
      id: 7,
      image: thailand,
      country: "Thailand",
    },
    {
      id: 8,
      image: japan,
      country: "Japan",
    },
    {
      id: 9,
      image: india,
      country: "India",
    },
  ];