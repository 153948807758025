import React from "react";
import { Container } from "react-bootstrap";
import { DiscoverStyles } from "../../Routes/discover/discover-styles";
import { useNavigate } from "react-router-dom";

const DiscoverBanner = () => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-image"
      style={{
        backgroundColor: "#005e84",
        width: "100%",
        height: "500px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container className="text-center">
        <h1 className="text-white fw-bold" style={{ fontSize: "42pt" }}>
          Nina Trvl LLC
        </h1>
        <h2 className="text-white fw-light" style={{ fontSize: "32pt" }}>
          Discover the trip of your dreams
        </h2>
        <h3 className="text-white fw-light">
          Choose from one of our two great options:
        </h3>
        <DiscoverStyles>
          <div className="button-container">
            <button
              onClick={() => {
                navigate("/survey");
              }}
              className=" discover-button ps-5 pe-5 mt-3"
            >
              Nina Discover
            </button>

            <button
              onClick={() => {
                navigate("/game");
              }}
              className=" discover-button ps-5 pe-5 mt-3"
            >
              Destination Game
            </button>
          </div>
        </DiscoverStyles>
      </Container>
    </div>
  );
};

export default DiscoverBanner;
